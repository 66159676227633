import React from "react";

function User() {
    return (
        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.4143 10.9312C11.1196 10.2331 10.6919 9.59891 10.155 9.06406C9.6197 8.52767 8.98563 8.10001 8.28778 7.80469C8.28153 7.80156 8.27528 7.8 8.26903 7.79688C9.24246 7.09375 9.87528 5.94844 9.87528 4.65625C9.87528 2.51562 8.1409 0.78125 6.00028 0.78125C3.85965 0.78125 2.12528 2.51562 2.12528 4.65625C2.12528 5.94844 2.75809 7.09375 3.73153 7.79844C3.72528 7.80156 3.71903 7.80312 3.71278 7.80625C3.01278 8.10156 2.38465 8.525 1.84559 9.06563C1.3092 9.60089 0.881533 10.235 0.586213 10.9328C0.296091 11.616 0.139622 12.3485 0.125276 13.0906C0.124859 13.1073 0.127784 13.1239 0.133878 13.1394C0.139973 13.1549 0.149115 13.1691 0.160764 13.181C0.172413 13.193 0.186334 13.2025 0.201707 13.2089C0.21708 13.2154 0.233594 13.2188 0.250276 13.2188H1.18778C1.25653 13.2188 1.31121 13.1641 1.31278 13.0969C1.34403 11.8906 1.8284 10.7609 2.68465 9.90469C3.57059 9.01875 4.74715 8.53125 6.00028 8.53125C7.2534 8.53125 8.42996 9.01875 9.3159 9.90469C10.1722 10.7609 10.6565 11.8906 10.6878 13.0969C10.6893 13.1656 10.744 13.2188 10.8128 13.2188H11.7503C11.767 13.2188 11.7835 13.2154 11.7988 13.2089C11.8142 13.2025 11.8281 13.193 11.8398 13.181C11.8514 13.1691 11.8606 13.1549 11.8667 13.1394C11.8728 13.1239 11.8757 13.1073 11.8753 13.0906C11.8597 12.3437 11.705 11.6172 11.4143 10.9312V10.9312ZM6.00028 7.34375C5.28309 7.34375 4.60809 7.06406 4.10028 6.55625C3.59246 6.04844 3.31278 5.37344 3.31278 4.65625C3.31278 3.93906 3.59246 3.26406 4.10028 2.75625C4.60809 2.24844 5.28309 1.96875 6.00028 1.96875C6.71746 1.96875 7.39246 2.24844 7.90028 2.75625C8.40809 3.26406 8.68778 3.93906 8.68778 4.65625C8.68778 5.37344 8.40809 6.04844 7.90028 6.55625C7.39246 7.06406 6.71746 7.34375 6.00028 7.34375Z" fill="#A7ADB2"/>
        </svg>
    );
}

export default User;
