import React from "react"
import {useDispatch, useSelector} from "react-redux"
import Snackbar from '@mui/material/Snackbar';
import {AlertActions} from "./actions/alert"
import MuiAlert from '@mui/material/Alert';

const CustomAlert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Alert = () => {
    const dispatch = useDispatch()
    const alert = useSelector(state => state.alert)

    const close = () => {
        dispatch(AlertActions.clear())
    }

    if (alert.message) {
        return (
            <Snackbar
                open={true}
                autoHideDuration={3500}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                onClose={close}
            >
                <CustomAlert onClose={close} severity={alert.type} sx={{width: '100%'}}>
                    {alert.message}
                </CustomAlert>
            </Snackbar>
        )
    } else {
        return null
    }
}
