function getError(status){
    switch (status){
        case 204:
            return "Ошибка, Нет содержимого!"
        case 400:
            return "Ошибка запроса, возможно уже используется!"
        case 401:
            return "Неверный логин пароль!"
        case 403:
            return "Доступа запрещен!"
        case 404:
            return "Ошибка, ничего не найдено!"
        case 405:
            return "Ошибка, не верный метод!"
        case 409:
            return "Ошибка, ресурс уже существует!"
        case 413:
            return "Ошибка, слишком большой ресурс!"
        case 500:
            return "Внутренняя ошибка сервера!"
        case 504:
            return "Ошибка, превышено время запроса!"
        default:
            return "Ошибка запроса!"
    }
}
export {getError};
