import React, {useEffect, useState} from "react"
import {getModulIdByName} from "../App/helpers/getModulIdByName";
import {Box, Grid, Tab, Tabs} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {DatabaseActions} from "./actions/database";
import {allyProps} from "../App/helpers/tabPanel";
import {makeStyles} from '@mui/styles';
import {Modul} from "./Modul";
import {Filter} from "./Filter";
import Stack from "@mui/material/Stack";

const useStyles = makeStyles(theme => ({
    content: {
        height: "100%"
    },
    container: {
        height: "100%",
        width: "100%",
        margin: 0,
    },
    tab_list: {
        width: "18%",
        "color": "#495055",
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: '#2595FF',
            borderRadius: "0px 4px 4px 0px",
            width: "4px",
            left: 0
        },
        '& .MuiTabs-flexContainer': {
            height: "100%",
        }
    },
    icon: {
        height: "26px",
        width: "36px",
        display: "block"
    },
    tab: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "13px",
        lineHeight: "14px",
        textTransform: "none",
        minHeight: "40px",
        height: "auto",
        textAlign: "left",
        justifyContent: "flex-start",
        '&.Mui-selected': {
            color: '#2595FF',
            backgroundColor: "#F1F9FF",
        },
    },
    filter: {
        height: "100%"
    },
    logo: {
        position: "absolute",
        marginLeft: "auto",
        marginRight: "auto",
        height: "150px",
        width: "150px",
        bottom: "90px"
    }
}))

export const Index = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {modul_database: modul} = useSelector(state => state.modul);
    const {filter} = useSelector(state => state.database);
    const [modulValue, setModulValue] = useState('1');
    const [loading, setLoading] = useState(false);
    const [loadingStudies, setLoadingStudies] = useState(false);

    useEffect(async () => {
        if (!loadingStudies && modulValue === '1') {
            setLoadingStudies(true)
            await dispatch(DatabaseActions.studies(filter, getModulIdByName(modul.name))).then(() => {
                setTimeout(
                    () => setLoadingStudies(false),
                    5000
                );
            })
        }
    }, [loadingStudies, modulValue]);

    const handleChange = (event, newValue) => {
        dispatch({
            type: "MODUL_DATABASE", payload: {
                id: newValue
            }
        })
    };

    const handleClick = (event) => {
        dispatch({type: "FILTER_CLEAR", payload: null})
        if (Number(event.target.id.slice(-1)) === modul.id) {
            setLoading(false)
        }
    };

    const handleChangeModul = (event, newValue) => {
        setModulValue(newValue);
        if (newValue === "1") {
            dispatch({type: "STUDY", payload: null})
        }
    };

    return (
        <Grid className={classes.content}>
            <Box
                sx={{flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: "100%"}}
            >
                <Tabs
                    variant="scrollable"
                    orientation="vertical"
                    value={modul.id}
                    className={classes.tab_list}
                    onChange={handleChange}
                    aria-label="Выбор меню"
                    scrollButtons={false}
                    sx={{borderRight: 1, borderColor: 'divider'}}
                >
                    <Tab
                        onClick={handleClick}
                        className={classes.tab}
                        label="Подсистема анализа и интерпретации онкологических генетических биомаркеров"
                        iconPosition="start"
                        {...allyProps(0)}
                    />
                    <Tab
                        onClick={handleClick}
                        className={classes.tab}
                        label="Подсистема анализа и интерпретации полногеномных данных"
                        iconPosition="start"
                        {...allyProps(2)}
                    />
                    <Tab
                        onClick={handleClick}
                        className={classes.tab}
                        label="Подсистема анализа и интерпретации микробиома"
                        iconPosition="start"
                        {...allyProps(4)}
                    />
                    {modulValue === '1' && <Filter/>}
                    {modulValue !== '1' && <Stack
                            direction="row"
                            justifyContent="center"
                        >
                            <img className={classes.logo} alt="logo" src="images/logo.png"/>
                        </Stack>}
                </Tabs>
                <Modul
                    partitionId={0}
                    loading={loading}
                    setLoading={setLoading}
                    value={modulValue}
                    setValue={setModulValue}
                    handleChange={handleChangeModul}
                />
            </Box>
        </Grid>
    );
}
