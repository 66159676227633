import React from 'react'
import {TextFieldWithError} from '../../App/components/Input/TextField';
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import * as Yup from "yup";
import {Field, Form, Formik} from 'formik';
import Typography from "@mui/material/Typography";
import {Submit} from "../../App/components/Button/Submit";
import {DictionaryActions} from "../actions/dictionary";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    form: {
        background: "white",
        padding: "35px",
        minHeight: "730px"
    },
    text_field: {
        border: "1px solid #DBDEDF",
        marginBottom: "15px",
        borderRadius: "2px",
        marginTop: "5px",
        padding: "2px",
        width: "90%",
        height: "30px"
    },
    generate_button: {
        height: '26px',
        boxShadow: 'none',
        background: '#33BE63',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '16px',
        '&:hover': {
            background: '#33BE63',
        }
    },
}))

let types = {
    "medicalInstitutions": "ЛПУ",
    "biomaterialSource": "Источник биоматериала",
}

export const Add = () => {
    const dispatch = useDispatch()
    const {modul_dictionary: modul} = useSelector(state => state.modul);
    const classes = useStyles()

    const getTypeName = () => {
        return types[modul.name]
    }

    const getActions = (values) => {
        switch (modul.name){
            case 'medicalInstitutions':
                return DictionaryActions.addMedicalInstitution(values)
            default:
                return DictionaryActions.addBiomaterialSource(values)
        }
    }

    const submit = (values) => {
        return dispatch(getActions(values)).then(
            _ => {
                values.name = ""
                dispatch({
                    type: "ALERT_SUCCESS", payload: {
                        message: 'Вы успешно добавили новый справочник!',
                        type: 'success'
                    }
                })
            }
        )
    }

    const textFieldProps = {
        disableUnderline: true,
    }

    return (
        <Grid>
            <Formik
                initialValues={{
                    name: "",
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string()
                        .required(`Введите название ${getTypeName()}`),
                })}
                onSubmit={submit}
            >
                {({
                      handleSubmit,
                      setFieldValue
                  }) => (
                    <Form className={classes.form}>
                        <Grid style={{width: "350px"}} container direction="row" justify="flex-start" alignItems="flex-start">
                            <Typography>Введите название {getTypeName()}</Typography>
                            <Field
                                className={classes.text_field}
                                align="center"
                                type="text"
                                size="small"
                                variant="standard"
                                name={`name`}
                                onChange={setFieldValue}
                                inputProps={textFieldProps}
                                component={TextFieldWithError}
                            />
                            <Submit
                                className={classes.generate_button}
                                variant='contained'
                                onClick={handleSubmit}
                                type='submit'
                            >
                                Добавить
                            </Submit>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    )
}
