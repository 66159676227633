import React from "react";

function Selected(props) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2Z" fill="#2595FF"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.9142 5.41421L6.70711 12.6213L2 7.91421L3.41421 6.5L6.70711 9.79289L12.5 4L13.9142 5.41421Z" fill="white"/>
        </svg>
    );
}

export default Selected;