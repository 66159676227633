import React, {useEffect} from "react"
import {CustomBreadcrumb} from "../App/components/CustomBreadcrumb";
import TabContext from '@mui/lab/TabContext';
import Add from "../App/components/Svg/Add";
import {UploadStudy} from "./Modul/UploadStudy";
import TabPanel from '@mui/lab/TabPanel';
import {makeStyles} from '@mui/styles';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import {Data} from "./Modul/Data";
import {useDispatch, useSelector} from "react-redux";
import {EditStudy} from "./Modul/EditStudy";

const useStyles = makeStyles(theme => ({
    container: {
        width: "100%",
        height: "100%",
        background: "#F9FAFA",
        padding: "24px 0px 0px 24px;"
    },
    icon: {
        height: "16px",
        width: "16px",
        display: "block",
        marginTop: "2px",
        marginRight: "5px"
    },
    tab_list: {
        marginTop: "24px",
        backgroundColor: "white",
        borderBottom: "1px solid #DBDEDF",
        color: "#495055",
        height: "60px",
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: '#2595FF',
            borderRadius: "4px 4px 0px 0px",
            height: "4px"
        },
    },
    tab: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        textTransform: "none",
        '&.Mui-selected': {
            color: '#2595FF',
        },
    },
    tab_panel: {
        padding: 0
    }
}))

export const Modul = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const {modul_database: modul} = useSelector(state => state.modul);
    const {study} = useSelector(state => state.database);
    const {partitionId, loading, setLoading, value, setValue, handleChange} = props

    useEffect(() => {
        cancelButton()
    }, [modul.id]);

    useEffect(() => {
        if (study) {
            setValue("3")
        }
    }, [study]);

    const cancelButton = () => {
        dispatch({type: "STUDY", payload: null})
        setValue("1")
    }

    const onFileAdded = (file, guid) => {
        dispatch({
            type: "UPLOAD_MANAGER_ADD_FILE_INFO", payload: {
                guid: guid,
                uniqueIdentifier: file.uniqueIdentifier,
                chunks: file.chunks.length
            }
        })
    }

    const onUploadProgress = (percent, guid, cancel) => {
        dispatch({
            type: "UPLOAD_MANAGER_CHANGE", payload: {
                percent: Math.round(percent * 100),
                cancel: cancel,
                guid: guid
            }
        })
    }


    const onComplete = (name) => {
        dispatch({
            type: "ALERT_SUCCESS", payload: {
                message: `Файл ${name} успешно загружен!`,
                type: 'success'
            }
        })
    }

    const onError = (guid) => {
        dispatch({
            type: "UPLOAD_MANAGER_ERROR", payload: {
                guid: guid,
                message: "Произошла ошибка при загрузке файла"
            }
        })
    }
    const events = {
        onUploadProgress: onUploadProgress,
        onError: onError,
        onComplete: onComplete,
        onFileAdded: onFileAdded
    }

    return (
        <Grid className={classes.container}>
            <CustomBreadcrumb
                partitionId={partitionId}
                onClickPath={cancelButton}
            />
            <Grid style={{width: "98%"}}>
                <TabContext value={value}>
                    <Box>
                        <TabList
                            className={classes.tab_list}
                            onChange={handleChange}
                            aria-label="Пункт меню"
                        >
                            <Tab
                                className={classes.tab}
                                label="Список исследований" value="1"
                            />
                            <Tab
                                className={classes.tab}
                                label="Загрузить исследование"
                                icon={<Add className={classes.icon}/>}
                                iconPosition="start"
                                value="2"
                            />
                            {study && <Tab
                                className={classes.tab}
                                label={study ? study.study_instance_uid : "Редактирование"}
                                value="3"
                            />
                            }
                        </TabList>
                    </Box>
                    <TabPanel className={classes.tab_panel} value="1">
                        <Data
                            loading={loading}
                            setLoading={setLoading}
                        />
                    </TabPanel>
                    <TabPanel className={classes.tab_panel} value="2">
                        <UploadStudy
                            events={events}
                            cancelButton={cancelButton}
                        />
                    </TabPanel>
                    <TabPanel className={classes.tab_panel} value="3">
                        <EditStudy
                            events={events}
                            cancelButton={cancelButton}
                        />
                    </TabPanel>
                </TabContext>
            </Grid>
        </Grid>
    )
}
