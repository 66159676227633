import React from "react";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ruLocale from 'date-fns/locale/ru';
import {makeStyles} from "@mui/styles";
import Box from "@mui/material/Box";

const useStyles = makeStyles(theme => ({
    date_picker: {
        width: "87%",
        border: "1px solid #DBDEDF",
        height: "30px",
        padding: "2px",
        background: "transparent",
        marginTop: "6px",
        paddingRight: "10px",
        borderRadius: "2px"
    },
    date_picker_input: {
        border: "none",
        width: "90%",
        fontSize: "16px",
        fontStyle: "normal",
        fontFamily: "Open Sans",
        fontWeight: 400
    },
}))
export const CustomDatePicker = (props) => {
    const classess = useStyles()
    const {
        value,
        onChange,
    } = (props)

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
            <DesktopDatePicker
                value={value}
                onChange={onChange}
                inputFormat="dd/MM/yyyy"
                renderInput={({inputRef, inputProps, InputProps}) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        className={classess.date_picker}
                    >
                        <input
                            className={classess.date_picker_input}
                            ref={inputRef} {...inputProps}
                        />{InputProps?.endAdornment}
                    </Box>
                )}
            />
        </LocalizationProvider>
    )
}
