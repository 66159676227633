export default function dictionary(state = {
       biomaterialSources: {
           data: [],
           total_page: 0
       },
       medicalInstitutions: {
           data: [],
           total_page: 0
       },
   },
   action
) {
    switch (action.type) {
        case "BIOMATERIAL_SOURCE":
            return {
                ...state,
                ...{
                    biomaterialSources: action.payload
                }
            }
        case "MEDICAL_INSTITUTION":
            return {
                ...state,
                ...{
                    medicalInstitutions: action.payload
                }
            }
        default:
            return state
    }
}
