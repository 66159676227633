import {HttpService} from "../../App/services/http"

export const DatabaseService = {
    studies,
    getStudy,
    sendStudies,
    sendResource,
    editStudies,
    deleteResource,
    requestReport,
    sendAtlas,
    downloadResource,
    getResource
}

function studies(filter = '', modulId) {
    const options = {
        method: "GET",
    }
    return HttpService.http({path: `/gen/studies?interpretationType=${modulId}&limit=10${filter}`}, options, true)
        .then(response => {
            return response
        })

}

function getStudy(id) {
    const options = {
        method: "GET",
    }

    return HttpService.http({path: `/gen/studies/${id}`}, options, true)
        .then(response => {
            return response
        })

}


function getResource(name) {
    const options = {
        method: "GET",
    }

    return HttpService.http({path: `/gen/resources?fileName=${name}`}, options, true)
        .then(response => {
            return response
        })

}

function sendStudies(values) {
    const options = {
        method: "POST",
        body: JSON.stringify(values)
    }

    return HttpService.http({path: "/gen/studies"}, options, true)
        .then(response => {
            return response
        })
}

function editStudies(id, values) {
    const options = {
        method: "PUT",
        body: JSON.stringify(values)
    }

    return HttpService.http({path: `/gen/studies/${id}`}, options, true)
        .then(response => {
            return response
        })
}

function sendResource(guid, file, events) {
    const options = {
        method: "POST",
        guid: file.guid,
        events: events,
        file: file,
        query: {
            resourceType: file.resource_type
        }
    }
    return HttpService.http({path: `/gen/interpretations/${guid}/resources/chunk`}, options, true)
}

function deleteResource(id) {
    const options = {
        method: "DELETE",
        responseType: 'blob'
    }

    return HttpService.http({path: `/gen/resources/${id}`}, options, true)
        .then(response => {
            return response
        })
}

function sendAtlas(guid) {
    const options = {
        method: "POST"
    }

    return HttpService.http({path: `/gen/interpretations/${guid}/send`}, options, true)
        .then(response => {
            return response
        })
}

function requestReport(guid, values) {
    const options = {
        method: "POST",
        body: JSON.stringify(values)
    }

    return HttpService.http({path: `/gen/interpretations/${guid}/request-report`}, options, true)
        .then(response => {
            return response
        })
}


function downloadResource(id) {
    const options = {
        method: "GET",
        responseType: "blob"
    }

    return HttpService.http({path: `/gen/resources/${id}`}, options, true)
        .then(response => {
            return response
        })
}



