import {HttpService} from "../../App/services/http"

export const UsersService = {
    users
}

function users() {
    const options = {
        method: "GET",
    }

    return HttpService.http({path: `/gen/auth/users`}, options, true)
        .then(response => {
            return response
        })

}
