import {HttpService} from '../../App/services/http'

export const UploadManagerService = {
    cancel
}

function cancel(chunks, identifier, filename) {
    const options = {
        method: 'DELETE'
    }

    return HttpService.http({path: `/gen/chunk?Chunks=${chunks}&Identifier=${identifier}&FileName=${filename}`}, options, true)
        .then(response => {
            return response
        })
}
