import React from 'react';
import {BrowserRouter, Navigate, Outlet, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {AccountActions} from "./Account/actions/account";
import {applyMiddleware, createStore} from "redux"
import {Provider, useDispatch} from "react-redux"
import {Dictionary} from './Dictionary/Index'
import AppReducers from "./App/reducers";
import {Header} from "./Account/Header";
import {Index} from "./Database/Index";
import {Grid} from '@mui/material';
import {Alert} from "./App/Alert";
import {Login} from "./Auth/Login";
import logger from "redux-logger"
import {render} from "react-dom"
import thunk from "redux-thunk"
import './index.css';
import {NoPage} from "./App/NoPage";

const theme = createTheme({
    typography: {
        fontFamily: [
            'Open Sans',
        ].join(','),
    },
    palette: {
        secondary: {
            main: "#808080"
        },
    },
});
let middleware = [thunk]
if (process.env.REACT_APP_MODE !== 'production') {
    middleware = [...middleware, logger]
}

const store = createStore(
    AppReducers,
    applyMiddleware(...middleware)
)

function RequireAuth({children}) {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const location = useLocation()

    if (localStorage.getItem("token") !== null) {
        let auth = dispatch(AccountActions.account(navigate)).then(_ => {
            return true
        })
        if(auth.result === undefined){
            return children;
        }
    }
    return <Navigate to="/login" state={{from: location}} replace/>;
}

render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <Grid container direction='column' justify='center' alignItems='stretch' style={{height: '100%'}}>
                <Alert/>
                <BrowserRouter>
                    <Routes>
                        <Route element={
                            <div style={{height: "100%"}}>
                                <Outlet/>
                            </div>
                        }>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/dictionary" element={<RequireAuth><Header/><Dictionary/></RequireAuth>}/>
                            <Route path="/" element={<RequireAuth><Header/><Index/></RequireAuth>}/>
                            <Route path="*" element={<NoPage />}> </Route>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </Grid>
        </Provider>
    </ThemeProvider>,
    document.getElementById('root')
);
