import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {allyProps} from "../App/helpers/tabPanel";
import {Box, Grid, Tab, Tabs} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Data} from "./Data"
import Stack from "@mui/material/Stack";

const useStyles = makeStyles(theme => ({
    content: {
        height: "100%"
    },
    container: {
        height: "100%",
        width: "100%",
        margin: 0,
    },
    tab_list: {
        width: "18%",
        "color": "#495055",
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: '#2595FF',
            borderRadius: "0px 4px 4px 0px",
            width: "4px",
            left: 0
        },
    },
    icon: {
        height: "26px",
        width: "36px",
        display: "block"
    },
    tab: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        textTransform: "none",
        minHeight: "40px",
        height: "40px",
        textAlign: "left",
        justifyContent: "flex-start",
        '&.Mui-selected': {
            color: '#2595FF',
            backgroundColor: "#F1F9FF",
        }
    },
    filter: {
        backgroundColor: "#F5F5F5",
        padding: "10px 10px 10px 10px"
    },
    filter_name: {
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "25px",
        color: "black"
    },
    logo: {
        position: "absolute",
        marginLeft: "auto",
        marginRight: "auto",
        height: "150px",
        width: "150px",
        bottom: "90px"
    }
}))

export const Dictionary = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {modul_dictionary: modul} = useSelector(state => state.modul);

    const handleChange = (event, newValue) => {
        dispatch({
            type: "MODUL_DICTIONARY", payload: {
                id: newValue
            }
        })
    };

    return (
        <Grid className={classes.content}>
            <Box
                sx={{flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: "100%"}}
            >
                <Tabs
                    orientation="vertical"
                    value={modul.id}
                    className={classes.tab_list}
                    onChange={handleChange}
                    aria-label="Выбор меню"
                    sx={{borderRight: 1, borderColor: 'divider'}}
                >
                    <Tab className={classes.tab} iconPosition="start" label="ЛПУ" {...allyProps(3)}/>
                    <Tab className={classes.tab} iconPosition="start"
                         label="Источники биоматериала" {...allyProps(4)}/>
                    <Stack
                        direction="row"
                        justifyContent="center"
                    >
                        <img className={classes.logo} alt="logo" src="images/logo.png"/>
                    </Stack>
                </Tabs>
                <Data partitionId={1}/>
            </Box>
        </Grid>
    );
}
