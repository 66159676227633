import React, {useState} from "react"
import {Breadcrumbs, IconButton, Typography} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Button from "@mui/material/Button";
import Home from '../components/Svg/Home'
import {makeStyles} from '@mui/styles';
import {Link} from 'react-router-dom';
import clsx from "clsx";
import {useSelector} from "react-redux";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {Dialogs} from "./Dialogs";
import Stack from "@mui/material/Stack";

const useStyles = makeStyles(theme => ({
    home_icon: {
        width: "16px",
        height: "16px",
        display: "block"
    },
    prev: {
        fontStyle: "normal",
        color: "black",
        textDecoration: "none",
        textTransform: "none",
        fontWeight: 400,
        fontSize: "16px",
        fontHeight: "16px",
        background: "transparent",
        "&:hover": {
            background: "transparent",
        }
    },
    current: {
        color: "#6E7880"
    },
}))

export const CustomBreadcrumb = (props) => {
    const classes = useStyles()
    const {partitionId, onClickPath} = props
    const {modul_dictionary, modul_database} = useSelector(state => state.modul);
    const [oncoDialog, setOncoDialog] = useState(false);
    const [wgsDialog, setWgsDialog] = useState(false);
    const [biotaDialog, setBiotaDialog] = useState(false);

    const partitions = [
        {
            name: "База данных",
            modules: {
                "Onco": "Подсистема анализа и интерпретации онкологических генетических биомаркеров",
                "Wgs": "Подсистема анализа и интерпретации полногеномных данных",
                "Microbiome": "Подсистема анализа и интерпретации микробиома"
            }
        },
        {
            name: "Справочник",
            modules: {
                "medicalInstitutions": "ЛПУ",
                "biomaterialSource": "Источник биоматериала",
            }
        }
    ]

    const getModulName = () => {
        return partitions[partitionId].modules[modul_database.name] ?? partitions[partitionId].modules[modul_dictionary.name]
    }

    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
        >
            <Breadcrumbs
                aria-label="breadcrumb"
                separator={<NavigateNextIcon fontSize="small"/>}
            >
                <Link to="/">
                    <Home className={classes.home_icon}/>
                </Link>
                <Button
                    className={classes.prev}
                    underline="hover"
                    color="inherit"
                    onClick={onClickPath}
                >
                    {partitions[partitionId].name}
                </Button>
                <Typography
                    className={clsx(classes.prev, classes.current)}>{getModulName()}</Typography>
            </Breadcrumbs>
            {partitionId === 0 && modul_database.name === 'Onco' && <IconButton
                onClick={e => setOncoDialog(true)}
                className={classes.help_button}
            >
                <HelpOutlineOutlinedIcon/>
            </IconButton>}
            {partitionId === 0 && modul_database.name === 'Wgs' && <IconButton
                onClick={e => setWgsDialog(true)}
                className={classes.help_button}
            >
                <HelpOutlineOutlinedIcon/>
            </IconButton>}
            {partitionId === 0 && modul_database.name === 'Microbiome' && <IconButton
                onClick={e => setBiotaDialog(true)}
                className={classes.help_button}
            >
                <HelpOutlineOutlinedIcon/>
            </IconButton>}
            {partitionId === 0 && <Dialogs
                oncoDialog={oncoDialog}
                setOncoDialog={setOncoDialog}
                wgsDialog={wgsDialog}
                setWgsDialog={setWgsDialog}
                biotaDialog={biotaDialog}
                setBiotaDialog={setBiotaDialog}
            />}
        </Stack>
    )
}
