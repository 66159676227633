import {Microbiome} from "../Modul/UploadStudy/Microbiome";
import {createGuid} from "../../App/helpers/createGuid";
import {Onco} from "../Modul/UploadStudy/Onco";
import {Wgs} from "../Modul/UploadStudy/Wgs";
import React from "react";
import * as Yup from "yup";

const validates = {
    'Onco': Yup.object().shape({
        genotype_data: Yup.object().shape({
            value: Yup.mixed().required('Загрузите Файл генотипа'),
            use: Yup.boolean().oneOf([false], 'Данный файл уже используется')
        })
    }),
    'Wgs': Yup.object().shape({
        forward_sequencing: Yup.object().shape({
            value: Yup.mixed().required('Загрузите Файл первичной обработки'),
            use: Yup.boolean().oneOf([false], 'Данный файл уже используется')
        }),
        reverse_sequencing: Yup.object().shape({
            value: Yup.mixed().required('Загрузите Файл обратной обработки'),
            use: Yup.boolean().oneOf([false], 'Данный файл уже используется')
        })
    }),
    'Microbiome': Yup.object().shape({
        sample: Yup.object().shape({
            value: Yup.mixed().required('Загрузите Файл генетического образца'),
            use: Yup.boolean().oneOf([false], 'Данный файл уже используется')
        }),
    }),
}

const modulFiles = {
    'Onco': {
        genotype_data: {
            value: null,
            resource_type: 10,
            use: false,
            name: "Файл генотипа",
            new: true,
            oldName: ""
        },
    },
    'Wgs': {
        forward_sequencing: {
            value: null,
            resource_type: 3,
            use: false,
            name: "Файл первичной обработки",
            new: true,
            oldName: ""
        },
        reverse_sequencing: {
            value: null,
            resource_type: 4,
            use: false,
            name: "Файл обратной обработки",
            new: true,
            oldName: ""
        },
    },
    'Microbiome': {
        sample: {
            value: null,
            resource_type: 0,
            use: false,
            name: "Файл генетического образца",
            new: true,
            oldName: ""
        },
        positive_control: {
            value: null,
            resource_type: 1,
            use: false,
            name: "Позитивный контроль",
            new: true,
            oldName: ""
        },
        negative_control: {
            value: null,
            resource_type: 2,
            use: false,
            name: "Негативный контроль",
            new: true,
            oldName: ""
        },
    },
}

const getModuleFiles = (values, setFieldValue, modulId, errors) => {
    const modules = {
        'Onco': <Onco
            errors={errors}
            values={values}
            setFieldValue={setFieldValue}
        />,
        'Wgs': <Wgs
            errors={errors}
            values={values}
            setFieldValue={setFieldValue}
        />,
        'Microbiome': <Microbiome
            errors={errors}
            values={values}
            setFieldValue={setFieldValue}
        />,
    }
    return modules[modulId]
}

const validateForm = (values) => {
    const guid = createGuid()
    const params = {
        ...values,
        ...{
            interpretation: {
                id: guid,
                ...values.interpretation
            }
        }
    }
    delete (params.files)
    for (const key in params) {
        if (!params[key] || (params[key] instanceof Date && isNaN(params[key]))) {
            delete (params[key])
        }
    }
    return params
}

export {modulFiles, getModuleFiles, validateForm, validates};
