import React from "react";

function Calendar(props) {
    return (
        <svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.4665 4.83333H5.53317V5.76666H6.4665V4.83333ZM8.9165 4.83333H7.98317V5.76666H8.9165V4.83333ZM4.0165 7.4H3.08317V8.33333H4.0165V7.4ZM6.4665 7.4H5.53317V8.33333H6.4665V7.4ZM9.49984 1.91666V0.604164C9.49984 0.488132 9.45374 0.376852 9.3717 0.294805C9.28965 0.212758 9.17837 0.166664 9.06234 0.166664C8.94631 0.166664 8.83503 0.212758 8.75298 0.294805C8.67093 0.376852 8.62484 0.488132 8.62484 0.604164V1.91666H3.37484V0.604164C3.37484 0.488132 3.32874 0.376852 3.2467 0.294805C3.16465 0.212758 3.05337 0.166664 2.93734 0.166664C2.82131 0.166664 2.71003 0.212758 2.62798 0.294805C2.54593 0.376852 2.49984 0.488132 2.49984 0.604164V1.91666H0.166504V11.25H11.8332V1.91666H9.49984ZM10.9582 10.375H1.0415V2.79166H10.9582V10.375Z" fill="#495055"/>
        </svg>
    );
}

export default Calendar;