import React, {useState} from "react"
import {CustomBreadcrumb} from "../App/components/CustomBreadcrumb";
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Grid, Tab} from '@mui/material';
import Add from "../App/components/Svg/Add";
import {Add as AddRow} from './Data/Add'
import {makeStyles} from '@mui/styles';
import {Index} from "./Data/Index";
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    container: {
        "width": "100%",
        "height": "100%",
        "background": "#F9FAFA",
        "padding": "24px 0px 0px 24px;"
    },
    menu: {
        "height": "100%",
    },
    home_icon: {
        "width": "16px",
        "height": "16px",
        "display": "block"
    },
    prev: {
        "font-style": "normal",
        "color": "black",
        "text-decoration": "none",
        "font-weight": 400,
        "font-size": "16px",
        "font-height": "16px",
    },
    current: {
        "color": "#6E7880"
    },
    icon: {
        "height": "16px",
        "width": "16px",
        "display": "block",
        "margin-top": "2px",
        "margin-right": "5px"
    },
    tab_list: {
        marginTop: "24px",
        backgroundColor: "white",
        borderBottom: "1px solid #DBDEDF",
        color: "#495055",
        height: "60px",
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: '#2595FF',
            borderRadius: "4px 4px 0px 0px",
            height: "4px"
        },
    },
    tab: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        textTransform: "none",
        '&.Mui-selected': {
            color: '#2595FF',
        },
    },
    tab_panel: {
        padding: 0
    }
}))

export const Data = (props) => {
    const classes = useStyles()
    const {partitionId} = props
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const cancelAddButton = () => {
        setValue("3")
    }

    return (
        <Grid className={classes.container}>
            <CustomBreadcrumb
                partitionId={partitionId}
                onClickPath={cancelAddButton}
            />
            <Grid style={{width: "98%"}}>
                <TabContext value={value}>
                    <Box>
                        <TabList
                            className={classes.tab_list}
                            onChange={handleChange}
                            aria-label="Пункт меню"
                        >
                            <Tab className={classes.tab} label="Данные" value="1"/>
                            <Tab className={classes.tab} label="Добавить" icon={<Add className={classes.icon}/>}
                                 iconPosition="start" value="2"/>
                        </TabList>
                    </Box>
                    <TabPanel className={classes.tab_panel} value="1"><Index/></TabPanel>
                    <TabPanel className={classes.tab_panel} value="2"><AddRow/></TabPanel>
                </TabContext>
            </Grid>
        </Grid>
    )
}
