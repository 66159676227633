import {combineReducers} from "redux"
import uploadManager from "../../Account/reducers/uploadManager";
import authentication from "../../Auth/reducers/authentication";
import dictionary from "../../Dictionary/reducers/dictionary";
import database from "../../Database/reducers/database";
import account from "../../Account/reducers/account";
import alert from "./alert";
import users from './users';
import modul from "./modul";

const CombineReducers = combineReducers({
    authentication,
    uploadManager,
    dictionary,
    database,
    account,
    modul,
    users,
    alert
})

const AppReducers = (state, action) => {
    return CombineReducers(state, action)
}

export default AppReducers
