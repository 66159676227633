import React from 'react';
import {CustomDatePicker} from '../../../App/components/Input/CustomDatePicker';
import {TextFieldWithError} from '../../../App/components/Input/TextField';
import Typography from '@mui/material/Typography';
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import {Field} from 'formik';

const useStyles = makeStyles(theme => ({
    text_field_text: {
        fontStyle: "normal",
        lineHeight: "20px",
        marginTop: "15px",
        fontSize: "18px",
        fontWeight: 600,
    },
    text_field: {
        border: "1px solid #DBDEDF",
        marginBottom: "15px",
        borderRadius: "2px",
        marginTop: "5px",
        padding: "2px",
        width: "90%",
        height: "30px"
    },
    select_text_field_resource: {
        border: "1px solid #DBDEDF",
        marginBottom: "15px",
        borderRadius: "2px",
        marginTop: "5px",
        padding: "2px",
        width: "100%",
        height: "37px"
    },
    select_text_field: {
        border: "1px solid #DBDEDF",
        marginBottom: "15px",
        borderRadius: "2px",
        marginTop: "5px",
        padding: "2px",
        width: "90%",
        height: "37px"
    },
}))

export const StaticForm = (props) => {
    const classes = useStyles()
    const {medicalInstitutions, biomaterialSources, values, setFieldValue} = props
    const textFieldProps = {
        disableUnderline: true,
    }

    return (
        <React.Fragment>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                <Grid item sm={3} container direction="column" justify="flex-start" alignItems="flex-start">
                    <Typography className={classes.text_field_text}>Идентификатор
                        образца*</Typography>
                    <Field
                        className={classes.text_field}
                        align="center"
                        type="text"
                        size="small"
                        name={`study_instance_uid`}
                        variant="standard"
                        onChange={setFieldValue}
                        inputProps={textFieldProps}
                        component={TextFieldWithError}
                    />
                </Grid>
                <Grid item sm={3} container direction="column" justify="flex-start" alignItems="flex-start">
                    <Typography className={classes.text_field_text}>ЛПУ</Typography>
                    <Field
                        className={classes.select_text_field}
                        as="select"
                        name="medical_institution"
                        value={values.medical_institution_id}
                        onChange={(e) => {
                            setFieldValue(`medical_institution_id`, e.target.value)
                        }}>
                        {medicalInstitutions.data.map(function (medicalInstitution) {
                            return <option key={medicalInstitution.id}
                                           value={medicalInstitution.id}>{medicalInstitution.name}</option>
                        })}
                    </Field>
                </Grid>
                <Grid item sm={3} container direction="column" justify="flex-start" alignItems="flex-start">
                    <Typography className={classes.text_field_text}>ФИО Пациента</Typography>
                    <Field
                        className={classes.text_field}
                        align="center"
                        type="text"
                        size="small"
                        name={`patient_name`}
                        variant="standard"
                        onChange={setFieldValue}
                        inputProps={textFieldProps}
                        component={TextFieldWithError}
                    />
                </Grid>
            </Grid>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                <Grid item sm={3} container direction="column" justify="flex-start" alignItems="flex-start">
                    <Typography
                        className={classes.text_field_text}
                    >
                        Источник биоматериала</Typography>
                    <Field
                        className={classes.select_text_field}
                        as="select"
                        name="biomaterial_source"
                        value={values.biomaterial_source_id}
                        onChange={(e) => {
                            setFieldValue(`biomaterial_source_id`, e.target.value)
                        }}>
                        {biomaterialSources.data.map(function (biomaterialSource) {
                            return <option
                                key={biomaterialSource.id}
                                value={biomaterialSource.id}
                            >{biomaterialSource.name}</option>
                        })}
                    </Field>
                </Grid>
                <Grid item sm={3} container direction="column" justify="flex-start" alignItems="flex-start">
                    <Typography className={classes.text_field_text}>Дата забора образца</Typography>
                    <CustomDatePicker
                        value={values.sampling_date}
                        onChange={(newValue) => {
                            setFieldValue('sampling_date', newValue)
                        }}
                    />
                </Grid>
                <Grid item sm={3} container direction="column" justify="flex-start" alignItems="flex-start">
                    <Typography className={classes.text_field_text}>Дата рождения</Typography>
                    <CustomDatePicker
                        value={values.patient_birth_date}
                        onChange={(newValue) => {
                            setFieldValue('patient_birth_date', newValue)
                        }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}