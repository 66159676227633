import {DatabaseService} from '../services/database'
import file from "../../App/components/Svg/File";

export const DatabaseActions = {
    studies,
    getStudy,
    sendStudies,
    editStudies,
    sendResource,
    deleteResource,
    downloadResource,
    requestReport,
    sendAtlas,
    getResource
}

function studies(filter, modulId) {
    let params = {}
    for(const key in filter){
        if(filter[key] !== null && filter[key] !== undefined){
            params[key] = filter[key]
        }
    }
    params = Object.keys(params).length ? '&' + new URLSearchParams(params).toString() : ''
    return dispatch => new Promise((resolve, reject) => {
        DatabaseService.studies(params, modulId)
            .then(
                response => {
                    dispatch({type: "STUDIES", payload: response})
                    resolve(response)
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function getStudy(id) {
    return dispatch => new Promise((resolve, reject) => {
        DatabaseService.getStudy(id)
            .then(
                response => {
                    dispatch({type: "STUDY", payload: response})
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function sendStudies(values) {
    return dispatch => new Promise((resolve, reject) => {
        DatabaseService.sendStudies(values)
            .then(
                response => {
                    dispatch({type: "SEND_STUDIES", payload: response})
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function editStudies(id, values) {
    return dispatch => new Promise((resolve, reject) => {
        DatabaseService.editStudies(id, values)
            .then(
                response => {
                    dispatch({type: "EDIT_STUDIES", payload: response})
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function sendResource(guid, file, events) {
    return DatabaseService.sendResource(guid, file, events)
}


function deleteResource(id) {
    return dispatch => new Promise((resolve, reject) => {
        DatabaseService.deleteResource(id)
            .then(
                response => {
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}


function sendAtlas(guid) {
    return dispatch => new Promise((resolve, reject) => {
        DatabaseService.sendAtlas(guid)
            .then(
                response => {
                    dispatch({type: "SEND_ATLAS", payload: response})
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function requestReport(guid, values) {
    return dispatch => new Promise((resolve, reject) => {
        DatabaseService.requestReport(guid, values)
            .then(
                response => {
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function getResource(name) {
    return dispatch => new Promise((resolve, reject) => {
        DatabaseService.getResource(name)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    reject(error.message)
                }
            )
    })
}

function downloadResource(id) {
    return dispatch => new Promise((resolve, reject) => {
        DatabaseService.downloadResource(id)
            .then(
                response => {
                    let filename = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
                    let extension = response.headers['content-disposition'].split('.')[1].split(';')[0];
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${filename}.${extension}`.replaceAll('"',''));
                    document.body.appendChild(link);
                    link.click();
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}
