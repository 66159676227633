export default function authentication(state = {}, action) {
    switch (action.type) {
        case "AUTH_LOGIN_REQUEST":
            return {}
        case "AUTH_LOGIN_SUCCESS":
            return {}
        case "AUTH_LOGIN_FAILURE":
            return {}
        default:
            return state
    }
}
