import React from 'react';
import {Submit} from "../../../App/components/Button/Submit.jsx";
import {DatabaseActions} from "../../actions/database";
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Input from "@mui/material/Input";
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import {useDispatch} from "react-redux";

const useStyles = makeStyles(theme => ({
    button: {
        background: "#2595FF",
        color: "white",
        boxShadow: "none",
        "&:hover": {
            background: "#2595FF"
        }
    },
    file_name: {
        marginLeft: "15px",
        color: "#2595FF",
        fontSize: "16px",
    }
}))

export const FileUpload = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {values, setFieldValue, value, type} = props

    const inputProps = {
        ...{
            type: 'file',
            name: value,
            onChange: async (event) => {
                setFieldValue(`files.${type}.new`, true)
                setFieldValue(`files.${type}.use`, false)
                setFieldValue(`files.${type}.value`, event.currentTarget.files[0])
                await dispatch(DatabaseActions.getResource(event.currentTarget.files[0].name)).then(
                    response => {
                        if (response) {
                            setFieldValue(`files.${type}.use`, true)
                        }else{
                            setFieldValue(`files.${type}.use`, false)
                        }
                    },
                )
            },
        }
    }

    const deleteFile = (id) => {
        dispatch(DatabaseActions.deleteResource(id)).then(_ => {
            setFieldValue(`files.${type}.new`, true)
            setFieldValue(`files.${type}.value`, null)
            setFieldValue(`files.${type}.use`, false)
            dispatch({
                type: "ALERT_SUCCESS", payload: {
                    message: `Вы успешно удалили файл!`,
                    type: 'success'
                }
            })
        })
    }

    return (
        <Grid container direction="row" justify="center" alignItems="center" style={{marginTop: "15px"}}>
            {(values.files[type] && values.files[type].new) && <React.Fragment>
                <Submit
                    variant="contained"
                    component="label"
                    size="small"
                    className={classes.button}
                >
                    Обзор
                    <Input
                        style={{display: "none"}}
                        inputProps={inputProps}
                    />
                </Submit>
                <Typography
                    className={classes.file_name}
                >
                    {values.files[type].value !== null && values.files[type].value !== undefined ? `Выбранный файл: ${values.files[type].value.name}` : ""}
                </Typography>
            </React.Fragment>}
            {(values.files[type] && !values.files[type].new) && <React.Fragment>
                <IconButton
                    style={{marginLeft: "12px", marginRight: "15px"}}
                    color="error"
                    aria-label="delete"
                    component="span"
                    onClick={e => deleteFile(values.files[type].id)}
                >
                    <DeleteIcon/>
                </IconButton>
                <Typography
                    className={classes.file_name}
                >
                    Раннее загруженный файл: {values.files[type].oldName}
                </Typography>
            </React.Fragment>}
        </Grid>
    )
}
