export default function alert(state = {}, action) {
    switch (action.type) {
        case "ALERT_SUCCESS":
            return action.payload
        case "ALERT_ERROR":
            return action.payload
        case "ALERT_INFO":
            return action.payload
        case "ALERT_CLEAR":
            return {}
        default:
            return state
    }
}
