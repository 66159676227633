import {HttpService} from '../../App/services/http'

export const AccountService = {
    account
}

function account() {
    const options = {
        method: 'GET'
    }

    return HttpService.http({path: '/gen/auth/account'}, options, true)
        .then(response => {
            return response
        })
}
