import {getPath} from "../helpers/http";
import {getError} from '../helpers/httpHandler'
import Resumable from "resumablejs";
import axios from 'axios';

export const HttpService = {
    http
}

function http(location = {path: ''}, options = {}, authorization = false, formData = false) {
    options.headers = Object.assign({
        "Accept": "application/json",
    }, options.headers ? options.headers : {})

    if (!formData) {
        options.headers["Content-Type"] = "application/json"
    }

    if (authorization) {
        options.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }

    if (options.params && Object.keys(options.params).length) {
        location.path = getPath(location.path, options.params)
    }

    const host = location.hasOwnProperty('host') ? location.host : ""


    if (options.method === 'POST' && options.hasOwnProperty("events")) {
        return sendChunks(`${host}${location.path}`, options)
    } else {
        let axiosConfig = {
            method: options.method,
            url: `${host}${location.path}`,
            data: options.body ? options.body : null,
            headers: options.headers
        }
        if (options.hasOwnProperty("responseType")) {
            axiosConfig.responseType = options.responseType
        }
        return axios.request(axiosConfig).then(e => handleResponse(e, options)).catch(error)
    }

}

function error(error) {
    if (error.response) {
        switch (error.response.status) {
            case 401:
                localStorage.removeItem("account")
                localStorage.removeItem("token")
                throw new Error(getError(error.response.status))
            default:
                throw new Error(getError(error.response.status))
        }
    } else if (axios.isCancel(error)) {
        throw new Error("Вы отменили загрузку файла")
    }
}

function handleResponse(response, options) {
    if (response.status === 204) {
        throw new Error(getError(204))
    }
    if (response.headers.hasOwnProperty("token")) {
        localStorage.setItem("token", response.headers.token)
    }
    return options.hasOwnProperty("responseType") ? response : response.data
}

function sendChunks(url, options) {
    const r = new Resumable({
        target: url,
        testChunks: false,
        chunkSize: 52428800,
        simultaneousUploads: 1,
        maxChunkRetries: 5,
        chunkNumberParameterName: 'chunkNumber',
        chunkSizeParameterName: 'chunkSize',
        currentChunkSizeParameterName: 'currentChunkSize',
        totalSizeParameterName: 'totalSize',
        typeParameterName: 'type',
        identifierParameterName: 'identifier',
        fileNameParameterName: 'filename',
        relativePathParameterName: 'relativePath',
        totalChunksParameterName: 'totalChunks',
        fileParameterName: "chunk",
        query: {
            ...options.query,
            ...{
                upload_token: options.file["name"]
            }
        },
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
        },
    });
    r.addFile(options.file.value);
    r.on("fileAdded", function (file) {
        options.events.onFileAdded(file, options.guid)
        r.upload();
    });
    r.on("progress", function () {
        options.events.onUploadProgress(r.progress(), options.guid, r.cancel)
    });
    r.on("fileSuccess", function (file) {
        options.events.onComplete(file.file.name)
    });
    r.on("error", function () {
        options.events.onError(options.guid)
    });
    return r
}
