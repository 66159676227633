import React from 'react'
import {useDispatch, useSelector} from "react-redux"
import {TextFieldWithError} from "../../App/components/Input/TextField";
import DialogActions from '@mui/material/DialogActions';
import {DictionaryActions} from "../actions/dictionary";
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@mui/material/Typography";
import {Field, Form, Formik} from 'formik';
import Dialog from '@mui/material/Dialog';
import Button from "@mui/material/Button";
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import * as Yup from "yup";

const useStyles = makeStyles(theme => ({
    cancel_button: {
        background: "#F9FAFA",
        border: "1px solid #DBDEDF",
        boxSizing: "border-box",
        borderRadius: "2px",
        boxShadow: "none",
        color: "black",
        '&:hover': {
            background: "#F9FAFA",
        },
        marginRight: "10px"
    },
    accept_button: {
        background: "#2595FF",
        border: "1px solid #2595FF",
        boxSizing: "border-box",
        borderRadius: "2px",
        boxShadow: "none",
        color: "white",
        '&:hover': {
            background: "#2595FF",
        }
    },
    text_field: {
        border: "1px solid #DBDEDF",
        marginBottom: "15px",
        borderRadius: "2px",
        marginTop: "5px",
        padding: "2px",
        width: "88%",
        height: "30px"
    },
}))


export const DictionaryDialog = (props) => {
    const {modul_dictionary: modul} = useSelector(state => state.modul);
    const dispatch = useDispatch()
    const {
        deleteDialog,
        setDeleteDialog,
        editDialog,
        setEditDialog,
        setLoading,
        row
    } = props
    const classes = useStyles()

    const handleClose = () => {
        setDeleteDialog(false);
        setEditDialog(false);
    };

    const textFieldProps = {
        disableUnderline: true,
    }

    const getEditAction = (values) => {
        switch (modul.id){
            case 1:
                return DictionaryActions.editBiomaterialSource(values)
            default:
                return DictionaryActions.editMedicalInstitution(values)
        }
    }

    const getDeleteAction = (id) => {
        switch (modul.id){
            case 1:
                return DictionaryActions.deleteBiomaterialSource(id)
            default:
                return DictionaryActions.deleteMedicalInstitution(id)
        }
    }

    const editSubmit = (values) => {
        return dispatch(getEditAction(values)).then(
            _ => {
                dispatch({
                    type: "ALERT_SUCCESS", payload: {
                        message: 'Вы успешно отредактировали справочник!',
                        type: 'success'
                    }
                })
                setLoading(false)
                handleClose()
            }
        )
    }

    const deleteSubmit = () => {
        return dispatch(getDeleteAction(row.id)).then(
            _ => {
                dispatch({
                    type: "ALERT_SUCCESS", payload: {
                        message: 'Вы успешно удалили справочник!',
                        type: 'success'
                    }
                })
                setLoading(false)
                handleClose()
            }
        )
    }

    return row ? (
        <Grid>
            <Dialog
                open={deleteDialog}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    Удалить значение справочника ?
                </DialogTitle>
                <DialogActions>
                    <Button
                        className={classes.cancel_button}
                        variant="contained"
                        onClick={handleClose}
                    >Отмена</Button>
                    <Button
                        className={classes.accept_button}
                        variant="contained"
                        onClick={deleteSubmit}
                        autoFocus
                    >
                        Удалить
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={editDialog}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    Редактировать значение справочника
                </DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={row}
                        validationSchema={Yup.object().shape({
                            name: Yup.string()
                                .required("Введите название"),
                        })}
                        onSubmit={editSubmit}
                    >
                        {({
                              handleSubmit,
                              setFieldValue
                          }) => (
                            <Form>
                                <Grid
                                    style={{
                                        minWidth: "620px"
                                    }}
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Typography>Значение справочника</Typography>
                                    <Field
                                        className={classes.text_field}
                                        align="center"
                                        type="text"
                                        size="small"
                                        name={`name`}
                                        variant="standard"
                                        onChange={setFieldValue}
                                        inputProps={textFieldProps}
                                        component={TextFieldWithError}
                                    />
                                </Grid>
                                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                                    <Button
                                        className={classes.cancel_button}
                                        variant="contained"
                                        onClick={handleClose}
                                    >
                                        Отмена
                                    </Button>
                                    <Button
                                        className={classes.accept_button}
                                        variant="contained"
                                        onClick={handleSubmit}
                                    >
                                        Сохранить изменения
                                    </Button>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </Grid>
    ) : null
}
