export default function uploadManager(state = {
    files: []
}, action) {
    switch (action.type) {
        case "UPLOAD_MANAGER_FILES":
            return {
                ...state,
                ...{
                    files: action.payload
                }
            }
        case "UPLOAD_MANAGER_ADD_FILES":
            return {
                ...state,
                ...{
                    files: [action.payload].concat(state.files)
                }
            }
        case "UPLOAD_MANAGER_CHANGE":
            return {
                ...state,
                ...{
                    files: state.files.length ? state.files.map(file => {
                        if (file.guid === action.payload.guid) {
                            file.percent = action.payload.percent
                            file.cancel = action.payload.cancel
                            file.message = action.payload.percent === 100 ? 'Отправлено' : 'Отменить'
                            file.status = action.payload.percent === 100 ? 'success' : 'pending'
                        }
                        return file
                    }) : []
                }
            }
        case "UPLOAD_MANAGER_DELETE":
            return {
                ...state,
                ...{
                    files: state.files.length ? state.files.filter(file => {
                        return file.guid !== action.payload.guid
                    }) : []
                }
            }
        case "UPLOAD_MANAGER_ERROR":
            return {
                ...state,
                ...{
                    files: state.files.length ? state.files.map(file => {
                        if (file.guid === action.payload.guid) {
                            file.status = 'error'
                            file.message = action.payload.message
                        }
                        return file
                    }) : []
                }
            }
        case 'UPLOAD_MANAGER_ADD_FILE_INFO':
            return {
                ...state,
                ...{
                    files: state.files.length ? state.files.map(file => {
                        return file.guid === action.payload.guid ? {
                            ...file,
                            ...action.payload
                        } : file
                    }) : []
                }
            }
        default:
            return state
    }
}
