export default function account(state = {
    account: []
}, action) {
    switch (action.type) {
        case "ACCOUNT":
            return {
                ...state,
                ...{
                    account: action.payload
                }
            }
        default:
            return state
    }
}
