import React from 'react';
import {FileUpload} from "./FileUpload";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles(theme => ({
    file_type: {
        width: "290px",
        fontWeight: 500,
        fontSize: "16px"
    },
    error: {
        fontSize: "12px",
        color: '#d32f2f',
    }
}))

export const Microbiome = (props) => {
    const classes = useStyles()
    const {values, setFieldValue, errors} = props


    return (
        <Grid>
            <Stack direction="row" alignItems="center" spacing={2}>
                <Typography className={classes.file_type}>Файл генетического образца*</Typography>
                <FileUpload
                    value="files.sample"
                    type="sample"
                    values={values}
                    setFieldValue={setFieldValue}
                />
            </Stack>
            {(errors && errors.hasOwnProperty('files') && errors.files.hasOwnProperty('sample')) && <React.Fragment>
                <Typography className={classes.error}>{errors.files.sample.value ?? ''}</Typography>
                <Typography className={classes.error}>{errors.files.sample.use ?? ''}</Typography>
            </React.Fragment>
            }
            <Stack direction="row" alignItems="center" spacing={2}>
                <Typography className={classes.file_type}>Позитивный контроль</Typography>
                <FileUpload
                    value="files.positive_control"
                    type="positive_control"
                    values={values}
                    setFieldValue={setFieldValue}
                />
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
                <Typography className={classes.file_type}>Негативный контроль</Typography>
                <FileUpload
                    value="files.negative_control"
                    type="negative_control"
                    values={values}
                    setFieldValue={setFieldValue}
                />
            </Stack>
        </Grid>
    )
}
