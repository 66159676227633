import React from "react";

function Lock() {
    return (
        <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.33333 6V4C9.33333 3.11594 8.98214 2.2681 8.35702 1.64298C7.7319 1.01786 6.88406 0.666666 6 0.666666C5.11595 0.666666 4.2681 1.01786 3.64298 1.64298C3.01786 2.2681 2.66667 3.11594 2.66667 4V6H0V15.3333H12V6H9.33333ZM3.66667 4C3.66667 3.38116 3.9125 2.78767 4.35008 2.35008C4.78767 1.9125 5.38116 1.66667 6 1.66667C6.61884 1.66667 7.21233 1.9125 7.64992 2.35008C8.0875 2.78767 8.33333 3.38116 8.33333 4V6H3.66667V4ZM11 14.3333H1V7H11V14.3333ZM6 11.9467C6.13207 11.9449 6.25825 11.8917 6.35164 11.7983C6.44504 11.7049 6.49827 11.5787 6.5 11.4467V9.88667C6.5 9.75406 6.44732 9.62688 6.35355 9.53311C6.25979 9.43934 6.13261 9.38667 6 9.38667C5.86739 9.38667 5.74022 9.43934 5.64645 9.53311C5.55268 9.62688 5.5 9.75406 5.5 9.88667V11.4467C5.50173 11.5787 5.55496 11.7049 5.64836 11.7983C5.74175 11.8917 5.86793 11.9449 6 11.9467Z" fill="#A7ADB2"/>
        </svg>
    );
}

export default Lock;
