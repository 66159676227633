import React from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        "background": "#2595FF",
        "border-radius": "2px",
        "padding": "6px 12px 9px",
        "font-family": 'Open Sans',
        "font-style": 'normal',
        "line-height": '20px',
        "font-size": "14px",
        "font-weight": "600",
        "color": "white",
        "height": "33px",
        "min-width": "0",
        "text-transform": "none",
        "&:disabled": {
            "background-color": "#c1c1c1",
            "color": "white"
        },
        '& .MuiButton-endIcon': {
            'padding-bottom': '3px'
        }
    }
}))

export const Submit = (props) => {
    const {children, size, loading} = props
    const classes = useStyles()

    return (
        <LoadingButton loading={loading} size={size} classes={{root: classes.root}} {...props}>{children ??
            <React.Fragment/>}</LoadingButton>
    )
}
