import React from "react"
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {makeStyles} from "@mui/styles";
import Stack from "@mui/material/Stack";
import {Link} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    container: {
        width: "100%",
        height: "100%"
    },
    bold: {
        fontSize: "64px",
        fontWeight: 900,
        lineHeight: 1
    },
    info: {
        marginTop: "15px",
        color: "gray"
    },
    link: {
        marginTop: "15px",
        background: "#2595FF",
        color: "white",
        textDecoration: "none",
        padding: "12px",
        borderRadius: "3px",
        transition: "0.5s",
        "&:hover": {
            boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.12)",
        }
    }
}))

export const NoPage = () => {
    const classes = useStyles()
    return (
        <Stack className={classes.container} direction="column" alignItems="center" justifyContent="center">
            <Typography className={classes.bold}>Что-то пошло не так</Typography>
            <Typography className={classes.info}>Запрашиваемая вами страница не существует, либо была
                удалена</Typography>
            <Link className={classes.link} to="/">Перейти на главную</Link>
        </Stack>
    )
}
