export default function users(state = {
    users: []
}, action) {
    switch (action.type) {
        case "USERS":
            return {
                ...state,
                ...{
                    users: action.payload,
                }
            }
        default:
            return state
    }
}
