import React from "react";

function Eye() {
    return (
        <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.7544 2.55045C10.6518 1.47504 9.01273 0.333332 7 0.333332C4.98727 0.333332 3.34825 1.47504 2.24557 2.55045C1.50145 3.27617 0.863514 4.10003 0.333336 5C0.863514 5.89997 1.50145 6.72383 2.24557 7.44955C3.34825 8.52496 4.98727 9.66667 7 9.66667C9.01273 9.66667 10.6518 8.52496 11.7544 7.44955C12.5017 6.72079 13.1263 5.89674 13.6667 5C13.1389 4.09931 12.4978 3.27542 11.7544 2.55045ZM2.94399 6.69859C3.96797 7.69726 5.36703 8.62963 7 8.62963C8.63297 8.62963 10.032 7.69726 11.056 6.69859C11.7389 6.03261 12.2289 5.36083 12.4711 5C12.2289 4.63917 11.7389 3.96739 11.056 3.3014C10.032 2.30273 8.63297 1.37037 7 1.37037C5.36703 1.37037 3.96797 2.30273 2.94399 3.3014C2.26112 3.96739 1.77114 4.63917 1.52891 5C1.77114 5.36083 2.26112 6.03261 2.94399 6.69859Z" fill="#A7ADB2"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7 2.40741C5.60176 2.40741 4.46827 3.56815 4.46827 5C4.46827 6.43185 5.60176 7.59259 7 7.59259C8.39824 7.59259 9.53174 6.43185 9.53174 5C9.53174 3.56815 8.39824 2.40741 7 2.40741ZM7 3.44444C6.16106 3.44444 5.48096 4.14089 5.48096 5C5.48096 5.85911 6.16106 6.55555 7 6.55555C7.83895 6.55555 8.51904 5.85911 8.51904 5C8.51904 4.14089 7.83895 3.44444 7 3.44444Z" fill="#A7ADB2"/>
        </svg>
    );
}

export default Eye;
