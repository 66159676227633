function getModulIdByName(modulId) {
    const modules = {
        'Onco': 1,
        'Wgs': 2,
        'Microbiome': 0,

    }
    return modules[modulId]
}

export {getModulIdByName};