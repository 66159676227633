import {DictionaryService} from '../services/dictionary'

export const DictionaryActions = {
    biomaterialSource,
    deleteBiomaterialSource,
    editBiomaterialSource,
    addBiomaterialSource,
    medicalInstitution,
    editMedicalInstitution,
    deleteMedicalInstitution,
    addMedicalInstitution
}

function biomaterialSource(params) {
    return dispatch => new Promise((resolve, reject) => {
        DictionaryService.biomaterialSource(params)
            .then(
                response => {
                    dispatch({type: "BIOMATERIAL_SOURCE", payload: response})
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function editBiomaterialSource(values) {
    return dispatch => new Promise((resolve, reject) => {
        DictionaryService.editBiomaterialSource(values)
            .then(
                _ => {
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}


function deleteBiomaterialSource(id) {
    return dispatch => new Promise((resolve, reject) => {
        DictionaryService.deleteBiomaterialSource(id)
            .then(
                _ => {
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}


function addBiomaterialSource(values) {
    return dispatch => new Promise((resolve, reject) => {
        DictionaryService.addBiomaterialSource(values)
            .then(
                _ => {
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}


function medicalInstitution(params) {
    return dispatch => new Promise((resolve, reject) => {
        DictionaryService.medicalInstitution(params)
            .then(
                response => {
                    dispatch({type: "MEDICAL_INSTITUTION", payload: response})
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

function editMedicalInstitution(values) {
    return dispatch => new Promise((resolve, reject) => {
        DictionaryService.editMedicalInstitution(values)
            .then(
                _ => {
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}


function deleteMedicalInstitution(id) {
    return dispatch => new Promise((resolve, reject) => {
        DictionaryService.deleteMedicalInstitution(id)
            .then(
                _ => {
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}


function addMedicalInstitution(values) {
    return dispatch => new Promise((resolve, reject) => {
        DictionaryService.addMedicalInstitution(values)
            .then(
                _ => {
                    dispatch({type: "DICTIONARY_SUCCESS"})
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}
