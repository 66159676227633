import React from "react"
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import {UploadManagerActions} from "./actions/uploadManager";
import LinearProgress from '@mui/material/LinearProgress';
import {useDispatch, useSelector} from "react-redux";
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import File from "../App/components/Svg/File";
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Stack from "@mui/material/Stack";
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";

const useStyles = makeStyles(theme => ({
    upload_manager: {
        "margin-right": "22px"
    },
    upload_manager_icon: {
        width: "32px",
        height: "32px",
    },
    container: {
        minWidth: "350px",
        height: "450px",
        padding: "15px"
    },
    title: {
        fontWeight: 600
    },
    no_upload: {
        marginTop: "50%",
        textAlign: "center",
    },
    no_upload_text: {
        color: "#9da5a9"
    },
    file: {
        width: "100%",
        height: "55px",
        borderBottom: "2px solid #DBDEDF",
        borderRadius: "2px",
        padding: "5px"
    },
    progress: {
        marginTop: "0px",
        background: "white",
        border: "1px solid #DBDEDF",
        borderRadius: "7px",
        padding: "2px"
    },
    upload_info: {
        width: "100%",
        paddingLeft: "10px",
    },
    file_name: {
        fontWeight: 400
    },
    linear_progress: {
        height: "10px",
        background: "white",
        borderRadius: "7px",
        '& .MuiLinearProgress-bar': {
            backgroundColor: "#2595FF"
        }
    },
    linear_progress_green: {
        height: "10px",
        background: "white",
        borderRadius: "7px",
        '& .MuiLinearProgress-bar': {
            backgroundColor: "#33BE63"
        }
    },
    button: {
        marginTop: "-10px",
    },
    percent: {
        marginTop: "-5px",
        marginLeft: "5px",
    },
    file_extension: {
        fontSize: "8px",
        position: "absolute",
        textAlign: "center",
        fontWeight: 700,
        width: "31px"
    }
}))

export const UploadManager = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {anchorEl, setAnchorEl} = props
    const {files} = useSelector(state => state.uploadManager);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const cancelUploadFile = (file) => {
        file.cancel()
        dispatch(UploadManagerActions.cancel(file.chunks, file.uniqueIdentifier, file.value.name))
        dispatch({
            type: "UPLOAD_MANAGER_DELETE", payload: {
                guid: file.guid
            }
        })
    }

    return (
        <Popover
            id='simple-popover'
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Grid
                className={classes.container}
            >
                <Typography className={classes.title}>Загрузка файлов</Typography>
                {files.length > 0 && files.map(file => {
                    return file ? <React.Fragment key={file.value.name}>
                        <Stack
                            className={classes.file}
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                        >
                            <Typography
                                className={classes.file_extension}>{file.value.name.match(/\.([^.]+)$|$/)[1] !== undefined ? file.value.name.match(/\.([^.]+)$|$/)[1].toUpperCase() : "FILE"}</Typography>
                            <File/>
                            <Grid
                                item
                                className={classes.upload_info}
                                direction="column"
                                alignItems="flex-start"
                                justifyContent="flex-start"
                            >
                                <Typography
                                    className={classes.file_name}>{file.value.name.length > 30 ? `${file.value.name.substr(0, 30)}...` : file.value.name}</Typography>
                                <Box sx={{flexGrow: 1}} className={classes.progress}>
                                    <LinearProgress
                                        className={classes.linear_progress}
                                        variant="determinate"
                                        value={file.percent}
                                    />
                                </Box>
                            </Grid>
                            <Grid
                                item
                                direction="column"
                                alignItems="flex-end"
                                justifyContent="flex-end"
                            >
                                {file.status !== undefined && <React.Fragment>
                                    {file.status === 'pending' &&
                                        <Tooltip title={file.message}>
                                            <IconButton
                                                onClick={e => cancelUploadFile(file)}
                                                className={classes.button}
                                            >
                                                <CancelOutlinedIcon color="error"/>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {file.status === 'error' &&
                                        <Tooltip title={file.message}>
                                            <IconButton>
                                                <ErrorOutlinedIcon color="error"/>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {file.status === 'success' &&
                                        <Tooltip title={file.message}>
                                            <IconButton>
                                                <CheckCircleOutlineOutlinedIcon color="success"/>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </React.Fragment>}
                                <Typography className={classes.percent}>{file.percent}%</Typography>
                            </Grid>
                        </Stack>
                    </React.Fragment> : null
                })}
                {files.length === 0 && <Grid className={classes.no_upload}>
                    <Typography className={classes.no_upload_text}>В данный момент нет загрузок</Typography>
                </Grid>}
            </Grid>
        </Popover>
    );
};
