import React from 'react';
import {FileUpload} from "./FileUpload";
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import * as Yup from "yup";

const useStyles = makeStyles(theme => ({
    file_type: {
        width: "290px",
        fontWeight: 500,
        fontSize: "16px"
    },
    error: {
        fontSize: "12px",
        color: '#d32f2f',
    }
}))

export const Onco = (props) => {
    const classes = useStyles()
    const {values, setFieldValue, errors} = props


    return (
        <Grid>
            <Stack direction="row" alignItems="center" justifyContent="flex-start">
                <Typography className={classes.file_type}>Файл генотипа*</Typography>
                <FileUpload
                    value="files.genotype_data"
                    type="genotype_data"
                    values={values}
                    setFieldValue={setFieldValue}
                />
            </Stack>
            {(errors && errors.hasOwnProperty('files') && errors.files.hasOwnProperty('genotype_data')) &&
                <React.Fragment>
                    <Typography className={classes.error}>{errors.files.genotype_data.value ?? ''}</Typography>
                    <Typography className={classes.error}>{errors.files.genotype_data.use ?? ''}</Typography>
                </React.Fragment>
            }
        </Grid>
    )
}
