import {HttpService} from "../../App/services/http"

export const DictionaryService = {
    biomaterialSource,
    deleteBiomaterialSource,
    editBiomaterialSource,
    addBiomaterialSource,
    medicalInstitution,
    editMedicalInstitution,
    deleteMedicalInstitution,
    addMedicalInstitution
}

function biomaterialSource(params) {
    const options = {
        method: "GET",
    }

    return HttpService.http({path: `/gen/biomaterial-source?limit=10&${params}`}, options, true)
        .then(response => {
            return response
        })

}

function editBiomaterialSource(values) {
    const options = {
        method: "PUT",
        body: JSON.stringify(values)
    }

    return HttpService.http({path: "/gen/biomaterial-source"}, options, true)
        .then(response => {
            return response
        })

}

function deleteBiomaterialSource(id) {
    const options = {
        method: "DELETE"
    }
    console.log(`/biomaterial-source/${id}`)
    return HttpService.http({path: `/gen/biomaterial-source/${id}`}, options, true)
        .then(response => {
            return response
        })

}

function addBiomaterialSource(values) {
    const options = {
        method: "POST",
        body: JSON.stringify(values)
    }

    return HttpService.http({path: `/gen/biomaterial-source`}, options, true)
        .then(response => {
            return response
        })

}


function medicalInstitution(params) {
    const options = {
        method: "GET",
    }

    return HttpService.http({path: `/gen/medical-institution?limit=10&${params}`}, options, true)
        .then(response => {
            return response
        })

}


function editMedicalInstitution(values) {
    const options = {
        method: "PUT",
        body: JSON.stringify(values)
    }

    return HttpService.http({path: "/gen/medical-institution"}, options, true)
        .then(response => {
            return response
        })

}

function deleteMedicalInstitution(id) {
    const options = {
        method: "DELETE"
    }
    console.log(`/biomaterial-source/${id}`)
    return HttpService.http({path: `/gen/medical-institution/${id}`}, options, true)
        .then(response => {
            return response
        })

}

function addMedicalInstitution(values) {
    const options = {
        method: "POST",
        body: JSON.stringify(values)
    }

    return HttpService.http({path: `/gen/medical-institution`}, options, true)
        .then(response => {
            return response
        })

}
