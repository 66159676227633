import React, {useEffect, useState} from 'react';
import {getModuleFiles, modulFiles, validateForm, validates} from "../helpers/study";
import {DictionaryActions} from "../../Dictionary/actions/dictionary";
import {getModulIdByName} from '../../App/helpers/getModulIdByName';
import {createGuid} from '../../App/helpers/createGuid';
import {useDispatch, useSelector} from "react-redux";
import {DatabaseActions} from '../actions/database';
import LoadingButton from '@mui/lab/LoadingButton';
import {StaticForm} from './UploadStudy/StaticForm';
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import {Form, Formik} from 'formik';
import * as Yup from "yup";

const useStyles = makeStyles(theme => ({
    form: {
        background: "white",
        padding: "35px"
    },
    send_button: {
        border: "1px solid #33BE63",
        boxSizing: "border-box",
        background: "#33BE63",
        borderRadius: "2px",
        boxShadow: "none",
        marginTop: "35px",
        color: "white",
        "&:hover": {
            background: "#33BE63",
        }
    },
    cancel_button: {
        border: "1px solid #d32f2f",
        boxSizing: "border-box",
        borderRadius: "2px",
        boxShadow: "none",
        marginTop: "35px",
        marginLeft: "15px",
        color: "white",
    }
}))

export const EditStudy = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {modul_database: modul} = useSelector(state => state.modul);
    const {account} = useSelector(state => state.account);
    const {study} = useSelector(state => state.database);
    const {biomaterialSources, medicalInstitutions} = useSelector(state => state.dictionary);
    const [loadingMi, setLoadingMi] = useState(false)
    const [loadingBs, setLoadingBs] = useState(false)
    const [loadingRow, setLoadingRow] = useState(false)
    const {cancelButton, events} = props
    const params = new URLSearchParams({
        isActive: true
    }).toString()


    useEffect(() => {
        if (!loadingRow) {
            dispatch(DatabaseActions.getStudy(study.id)).then(_ => {
                setLoadingRow(true)
            })
        }
    }, [dispatch]);

    useEffect(() => {
        if (!loadingMi) {
            dispatch(DictionaryActions.medicalInstitution(params)).then(_ => {
                setLoadingMi(true)
            })
        }
    }, [dispatch, loadingMi]);

    useEffect(() => {
        if (!loadingBs) {
            dispatch(DictionaryActions.biomaterialSource(params)).then(_ => {
                setLoadingBs(true)
            })
        }
    }, [dispatch, loadingBs]);

    const sendResource = async (guid, file) => {
        file = {
            ...file,
            ...{
                guid: createGuid(),
                percent: 0,
                status: 'pending',
                message: "Отменить"
            }
        }
        dispatch({
            type: "UPLOAD_MANAGER_ADD_FILES", payload: file
        })
        await dispatch(DatabaseActions.sendResource(guid, file, events))
    }

    const prepareResources = (values, {setSubmitting}) => {
        setSubmitting(false)
        let params = validateForm(values)
        delete (params.interpretation)
        dispatch(DatabaseActions.editStudies(study.id, params)).then(
            _ => {
                dispatch({
                    type: "ALERT_SUCCESS", payload: {
                        message: 'Вы успешно отредактировали исследование.',
                        type: 'success'
                    }
                })
                for (const key in values.files) {
                    if (values.files[key].value !== null && values.files[key].new) {
                        sendResource(study.interpretation.id, values.files[key])
                    }
                }
                cancelButton()
            }
        )
    }

    const getStudyFiles = (resources) => {
        let useFiles = modulFiles[modul.name]
        for (const key in useFiles) {
            const resourceFiles = resources.filter(resource => resource.type === key)
            if (resourceFiles.length) {
                useFiles[key] = {
                    value: useFiles[key].value,
                    resource_type: useFiles[key].resource_type,
                    use: useFiles[key].use,
                    name: useFiles[key].name,
                    new: false,
                    oldName: resourceFiles[0].file_name,
                    id: resourceFiles[0].id
                }
            }
        }
        return useFiles
    }

    return (loadingMi && loadingBs && loadingRow && study) ? (
        <Grid key={modul.id}>
            <Formik
                initialValues={{
                    study_instance_uid: study.study_instance_uid,
                    patient_name: study.patient_name,
                    patient_birth_date: new Date(study.patient_birth_date),
                    referring_physician_name: account.username,
                    biomaterial_source_id: study.biomaterial_source_id,
                    medical_institution_id: study.medical_institution_id,
                    sampling_date: new Date(study.sampling_date),
                    interpretation: {
                        "emulation": true,
                        "type": getModulIdByName(modul.name)
                    },
                    files: study.interpretation.resources.length ? getStudyFiles(study.interpretation.resources) : modulFiles[modul.name]
                }}
                validationSchema={Yup.object().shape({
                    study_instance_uid: Yup.string()
                        .required("Введите идентификатор образца"),
                    files: validates[modul.name]
                })}
                onSubmit={prepareResources}
            >
                {({
                      errors,
                      values,
                      handleSubmit,
                      setFieldValue
                  }) => (
                    <Form className={classes.form}>
                        <StaticForm
                            values={values}
                            setFieldValue={setFieldValue}
                            biomaterialSources={biomaterialSources}
                            medicalInstitutions={medicalInstitutions}
                        />
                        {getModuleFiles(values, setFieldValue, modul.name, errors)}
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                            <LoadingButton
                                variant="contained"
                                onClick={handleSubmit}
                                className={classes.send_button}
                            >
                                Сохранить
                            </LoadingButton>
                            <LoadingButton
                                color="error"
                                variant="contained"
                                onClick={cancelButton}
                                className={classes.cancel_button}
                            >
                                Отменить
                            </LoadingButton>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    ) : null
}
