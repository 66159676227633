import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import TableContainer from '@mui/material/TableContainer';
import {DictionaryActions} from "../actions/dictionary";
import Pagination from '@mui/material/Pagination';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import Button from "@mui/material/Button";
import {DictionaryDialog} from "./Dialog";
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles(theme => ({
    cancel_button: {
        background: "#F9FAFA",
        border: "1px solid #DBDEDF",
        boxSizing: "border-box",
        borderRadius: "2px",
        boxShadow: "none",
        color: "black",
        '&:hover': {
            background: "#F9FAFA",
        }
    },
    accept_button: {
        background: "#2595FF",
        border: "1px solid #2595FF",
        boxSizing: "border-box",
        borderRadius: "2px",
        boxShadow: "none",
        color: "white",
        '&:hover': {
            background: "#2595FF",
        }
    },
    footer: {
        paddingTop: "30px",
        paddingBottom: "30px",
        margin: "auto",
        width: "max-content"
    },
    gray_text: {
        color: "#808080"
    }
}))


const columns = [
    {field: 'name', name: 'Название ', width: 300},
    {field: 'actions', name: 'Действия', width: 30},
];


export const Index = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {modul_dictionary: modul} = useSelector(state => state.modul);
    const {biomaterialSources, medicalInstitutions} = useSelector(state => state.dictionary);
    const [loading, setLoading] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [useRow, setUseRow] = useState(null);
    const [page, setPage] = useState(1);

    const setRow = (target) => {
        setUseRow({
            id: target.id,
            name: target.name,
            is_active: true
        })
    }

    const handleClickOpenEdit = (e) => {
        setRow(e.target)
        setEditDialog(true)
    };

    const handleClickOpenDelete = (e) => {
        setRow(e.target)
        setDeleteDialog(true)
    };

    useEffect(() => {
        setPage(1)
        setLoading(false)
    }, [modul.id]);

    useEffect(() => {
        const params = new URLSearchParams({
            offset: page === 1 ? 0 : (10 * page) - 10
        }).toString()
        switch (modul.name) {
            case 'biomaterialSource':
                const getBiomaterialSources = async () => {
                    return await dispatch(DictionaryActions.biomaterialSource(params))
                }
                if (!loading) {
                    getBiomaterialSources().then(r => {
                        setLoading(true)
                    })
                }
                break;
            default:
                const getMedicalInstitutions = async () => {
                    return await dispatch(DictionaryActions.medicalInstitution(params))
                }
                if (!loading) {
                    getMedicalInstitutions().then(r => {
                        setLoading(true)
                    })
                }
                break;
        }


    }, [dispatch, loading]);

    const getRows = () => {
        switch (modul.name) {
            case 'biomaterialSource':
                return biomaterialSources.data
            default:
                return medicalInstitutions.data
        }
    }

    const getCount = () => {
        switch (modul.name) {
            case 'biomaterialSource':
                return biomaterialSources.total_pages
            default:
                return medicalInstitutions.total_pages
        }
    }

    const getSkeleton = () => {
        let rows = []
        for (var i = 0; i < 9; i++) {
            rows[i] = <TableRow key={i}>
                <TableCell>
                    <Skeleton variant="text" width={"100%"} height={40}/>
                </TableCell>
                <TableCell>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                        <Skeleton variant="text" width={150} height={40}/>
                        <Skeleton variant="text" width={150} height={40} style={{marginLeft: "15px"}}/>
                    </Grid>
                </TableCell>
            </TableRow>
        }
        return rows
    }

    const toPage = (event, value) => {
        setPage(value);
        setLoading(false)
    };

    const showSuccessActive = () => {
        setLoading(false)
        dispatch({
            type: "ALERT_SUCCESS", payload: {
                message: 'Вы успешно активировали справочник!',
                type: 'success'
            }
        })
    }
    const handleClickActive = (event) => {
        const params = {
            name: event.target.name,
            is_active: true,
            id: event.target.id
        }
        switch (modul.name) {
            case 'biomaterialSource':
                dispatch(DictionaryActions.editBiomaterialSource(params)).then(r => {
                    showSuccessActive()
                })
                break;
            default:
                dispatch(DictionaryActions.editMedicalInstitution(params)).then(r => {
                    showSuccessActive()
                })
                break;
        }
    }

        return (
            <Grid>
                <Paper style={{boxShadow: 'none'}}>
                    <TableContainer style={{height: "calc(100vh - 315px)"}}>
                        <Table stickyHeader aria-label='sticky table'>
                            <TableHead>
                                <TableRow>
                                    {columns.map(column => (
                                        <TableCell
                                            style={{width: column.width, fontWeight: 600}}
                                            key={column.field}
                                        >
                                            {column.name}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? getRows().map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell className={!row.is_active ? classes.gray_text : null}>{row.name}</TableCell>
                                        <TableCell>
                                            {row.is_active && <React.Fragment>
                                                <Button
                                                    id={row.id}
                                                    name={row.name}
                                                    onClick={handleClickOpenEdit}
                                                    variant="text"
                                                >
                                                    Редактировать
                                                </Button>
                                                <Button
                                                    id={row.id}
                                                    name={row.name}
                                                    onClick={handleClickOpenDelete}
                                                    variant="text"
                                                    color="error"
                                                >
                                                    Удалить
                                                </Button>
                                            </React.Fragment>}
                                            {!row.is_active && <Button
                                                id={row.id}
                                                name={row.name}
                                                onClick={handleClickActive}
                                                variant="text"
                                                color="secondary"
                                            >
                                                Активировать
                                            </Button>}
                                        </TableCell>
                                    </TableRow>
                                )) : getSkeleton()}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid
                        className={classes.footer}
                    >
                        <Pagination
                            page={page}
                            onChange={toPage}
                            count={getCount()}
                            variant="outlined"
                            showFirstButton
                            showLastButton
                        />
                    </Grid>
                </Paper>
                <DictionaryDialog
                    deleteDialog={deleteDialog}
                    setDeleteDialog={setDeleteDialog}
                    editDialog={editDialog}
                    setEditDialog={setEditDialog}
                    row={useRow}
                    setLoading={setLoading}
                />
            </Grid>
        )
    }
