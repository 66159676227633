import React from "react";

function Home(props) {
    return (
        <svg {...props} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 0.366669L0 3.83334V12.5H12V3.83334L6 0.366669ZM5 11.5V8.16667H7V11.5H5ZM11 11.5H8V7.16667H4V11.5H1V4.41334L6 1.52667L11 4.41334V11.5Z" fill="black"/>
        </svg>
    );
}

export default Home;
