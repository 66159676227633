import {UsersService} from "../services/users";


export const UsersActions = {
    users,
}

function users() {
    return dispatch => new Promise((resolve, reject) => {
        UsersService.users()
            .then(
                response => {
                    dispatch({type: "USERS", payload: response})
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}
