import React from "react";

function Search(props) {
    return (
        <svg {...props} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.6667 13L9.77335 9.06667C10.6478 8.01051 11.0819 6.65814 10.9855 5.29034C10.8891 3.92255 10.2696 2.64444 9.25559 1.7214C8.24161 0.798365 6.91108 0.301323 5.54027 0.333485C4.16946 0.365646 2.86371 0.92454 1.89413 1.89411C0.924555 2.86369 0.365661 4.16945 0.3335 5.54026C0.301339 6.91107 0.79838 8.2416 1.72142 9.25558C2.64446 10.2696 3.92257 10.8891 5.29036 10.9855C6.65815 11.0819 8.01053 10.6478 9.06668 9.77333L13 13.6667L13.6667 13ZM1.33335 5.66667C1.33335 4.80961 1.58749 3.97181 2.06365 3.25919C2.5398 2.54658 3.21657 1.99117 4.00839 1.66319C4.8002 1.33521 5.67149 1.24939 6.51207 1.4166C7.35266 1.5838 8.12478 1.99651 8.73081 2.60254C9.33684 3.20856 9.74955 3.98069 9.91675 4.82127C10.084 5.66186 9.99814 6.53315 9.67016 7.32496C9.34218 8.11677 8.78677 8.79355 8.07415 9.2697C7.36154 9.74585 6.52373 10 5.66668 10C4.51795 9.99824 3.41677 9.54112 2.6045 8.72885C1.79222 7.91657 1.33511 6.8154 1.33335 5.66667Z" fill="#A7ADB2"/>
        </svg>
    );
}

export default Search;
