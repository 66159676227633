import React from 'react'
import NotSelected from '../../../App/components/Svg/Checkbox/NotSelected'
import Selected from '../../../App/components/Svg/Checkbox/Selected'
import {Submit} from '../../../App/components/Button/Submit.jsx'
import {DatabaseActions} from '../../actions/database'
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Popover from '@mui/material/Popover';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import {Form, Formik} from 'formik';
import clsx from 'clsx'
import {getModulIdByName} from "../../../App/helpers/getModulIdByName";

const useStyles = makeStyles(theme => ({
    popover: {
        width: '600px',
        height: '450px',
        padding: '24px'
    },
    popover_title: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '20px',
    },
    popover_title_child: {
        marginTop: '20px',
        marginBottom: '10px',
        fontSize: '14px',
    },
    generate_button: {
        marginTop: "30px",
        height: '26px',
        boxShadow: 'none',
        background: '#33BE63',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '16px',
        '&:hover': {
            background: '#33BE63',
        }
    },
    report_name: {
        marginTop: '10px',
        marginLeft: '3px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '20px',
        color: "black"
    },
    report_title: {
        marginTop: '10px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '20px',
    },
    checkbox_label: {
        marginTop: '5px',
    }

}))

const report_sections = [
    [
        {
            name: "Справка",
            type: "notes",
            value: true
        },
        {
            name: "Рекомендации",
            type: "recommendations",
            value: true
        }
    ],
    [
        {
            name: "Краткий отчет",
            type: "summary",
            value: true
        },
        {
            name: "Результат",
            type: "results",
            value: true
        },
        {
            name: "Генетика",
            type: "genetics",
            value: true
        },
        {
            name: "Литература",
            type: "publications",
            value: true
        },
    ],
    [
        {
            name: "Справка",
            type: "references",
            value: true
        },
        {
            name: "Гены ",
            type: "genes",
            value: true
        },
        {
            name: "Лекарственные вещества",
            type: "drugs",
            value: true
        },
        {
            name: "Литература",
            type: "publications",
            value: true
        },
        {
            name: "Генетика",
            type: "genetics",
            value: true
        }
    ]
]

export const Report = (props) => {
    const {modul_database: modul} = useSelector(state => state.modul);
    const {anchorEl, setAnchorEl, customReportType, row} = props
    const classes = useStyles()
    const dispatch = useDispatch()
    const popoverOpen = Boolean(anchorEl);

    const popoverClose = () => {
        setAnchorEl(null);
    };

    const getCustomReportType = () => {
        if (customReportType.indexOf('hereditary') !== -1) {
            return 1
        } else if (customReportType.indexOf('pgx') !== -1) {
            return 2
        }
        return 0
    }


    const submit = (values) => {
        let params = {
            custom_report_type: getCustomReportType(),
            interpretation_type: getModulIdByName(modul.name),
            include_blocks: [],
            extra_properties: []
        }
        values.includeBlocks.forEach(includeBlock => {
            if (includeBlock.value) {
                params.include_blocks.push(includeBlock.type)
            }
        })
        values.extraProperties.forEach(extraProperty => {
            if (extraProperty.value && extraProperty.content) {
                params.extra_properties.push({
                    label: extraProperty.label,
                    content: extraProperty.content
                })
            }
        })
        dispatch(DatabaseActions.requestReport(row.interpretation_id, params)).then(_ => {
            dispatch({
                type: "ALERT_SUCCESS", payload: {
                    message: "Отчет формируется. Это может занять некоторое время",
                    type: 'success'
                }
            })
            popoverClose()
        })
    }

    const change = (e, setFieldValue) => {
        setFieldValue(e.target.name, e.target.checked)
    }

    const getReportName = () =>{
        if (customReportType.indexOf('hereditary') !== -1) {
            return 'наследственным заболеваниям'
        } else if (customReportType.indexOf('pgx') !== -1) {
            return 'фармакогенетике'
        }
        return 'микробиоте'
    }

    return row ? (
        <Popover
            className={classes.popover}
            sx={{boxShadow: 0}}
            id='reports_popover'
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={popoverClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Formik
                initialValues={{
                    includeBlocks: report_sections[getCustomReportType()],
                    extraProperties: [
                        {
                            label: "ФИО пациента",
                            name: "patient_name",
                            content: row.patient_name,
                            value: true
                        },
                        {
                            label: "Идентификатор образца",
                            name: "study_instance_uid",
                            content: row.study_instance_uid,
                            value: true
                        },
                        {
                            label: "Дата рождения",
                            name: "patient_birth_date",
                            content: row.patient_birth_date,
                            value: true
                        },
                        {
                            label: "Дата забора образца",
                            name: "sampling_date",
                            content: row.sampling_date,
                            value: true
                        },
                        {
                            label: "Источник биоматериала",
                            name: "biomaterial_source",
                            content: row.biomaterial_source.name,
                            value: true
                        },
                        {
                            label: "Дата исследования",
                            name: "created_at",
                            content: row.created_at,
                            value: true
                        },
                    ]
                }}
                onSubmit={submit}

            >
                {({
                      values,
                      handleSubmit,
                      setFieldValue
                  }) => (
                    <Form>
                        <Grid className={classes.popover} container direction='column' justify='center'
                              alignItems='center'>
                            <Grid container direction='column' justify='flex-start' alignItems='flex-start'>
                                <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
                                    <Typography className={classes.report_title}>Конфигуратор отчета по </Typography>
                                    <Typography className={classes.report_name}>{getReportName()}</Typography>
                                </Grid>
                                <Typography className={clsx(classes.popover_title, classes.popover_title_child)}>Титульный
                                    лист</Typography>
                            </Grid>
                            <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
                                {values.extraProperties.map((extraProperty, index) => {
                                    return <Grid
                                        key={extraProperty.name}
                                        item sm={6}
                                        container
                                        direction='row'
                                        justify='flex-start'
                                        alignItems='flex-start'
                                    >
                                        <Checkbox
                                            name={`extraProperties[${index}].value`}
                                            icon={<NotSelected/>}
                                            checked={values.extraProperties[index].value}
                                            checkedIcon={<Selected/>}
                                            onChange={e => change(e, setFieldValue)}
                                        />
                                        <Typography className={classes.checkbox_label}>{extraProperty.label}</Typography>
                                    </Grid>
                                })}
                            </Grid>
                            <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
                                <Typography className={clsx(classes.popover_title, classes.popover_title_child)}>Разделы
                                    отчета</Typography>
                            </Grid>
                            <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
                                {values.includeBlocks.map((includeBlock, index) => {
                                    return <Grid
                                        key={includeBlock.type}
                                        item sm={6}
                                        container
                                        direction='row'
                                        justify='flex-start'
                                        alignItems='flex-start'
                                    >
                                        <Checkbox
                                            name={`includeBlocks[${index}].value`}
                                            icon={<NotSelected/>}
                                            checked={values.includeBlocks[index].value}
                                            checkedIcon={<Selected/>}
                                            onChange={e => change(e, setFieldValue)}
                                        />
                                        <Typography className={classes.checkbox_label}>{includeBlock.name}</Typography>
                                    </Grid>
                                })}
                            </Grid>
                            <Submit
                                className={classes.generate_button}
                                variant='contained'
                                onClick={handleSubmit}
                                type='submit'
                            >
                                Сформировать отчет
                            </Submit>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Popover>
    ) : null
}
