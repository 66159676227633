import {UploadManagerService} from "../services/uploadManager";


export const UploadManagerActions = {
    cancel
}

function cancel(chunks, identifier, filename) {
    return dispatch => new Promise((resolve, reject) => {
        UploadManagerService.cancel(chunks, identifier, filename)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}
