import React, {useState} from "react"
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    Tab,
    Tabs,
    Typography
} from '@mui/material';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme => ({
    descriptionItem: {
        fontFamily: "Open Sans",
        display: "list-item",
        margin: "0 0 15px",
        fontSize: "18px",
    },
    dialog_title: {
        fontWeight: 600
    }
}))

const OncoData = [
    "рак молочной железы;",
    "рак яичников;",
    "рак предстательной железы;",
    "рак поджелудочной железы."
]

const WgsData = [
    "Аторвастатин;",
    "Церивастатин;",
    "Флувастатин;",
    "Ловастатин;",
    "Питавастатин;",
    "Правастатин;",
    "Розувастатин;",
    "Симвастатин;",
    "Декслансопразол;",
    "Эзомепразол;",
    "Лансопразол;",
    "Омепразол;",
    "Пантопразол;",
    "Рабепразол."
]

const BiotaData = [
    "Сахарный диабет 2 типа;",
    "Ожирение;",
    "Болезнь Крона;",
    "Ишемическая болезнь сердца;",
    "Язвенный колит."
]

export const Dialogs = (props) => {
    const classes = useStyles()
    const {oncoDialog, setOncoDialog, wgsDialog, setWgsDialog, biotaDialog, setBiotaDialog} = props

    const handleClose = () => {
        setOncoDialog(false)
        setWgsDialog(false)
        setBiotaDialog(false)
    };

    return (
        <Grid className={classes.content}>
            <Dialog onClose={handleClose} open={oncoDialog}>
                <DialogTitle className={classes.dialog_title}>Подсистема подтверждает или опровергает наличие у пациента
                    следующих заболеваний:</DialogTitle>
                <DialogContent>
                    <List style={{listStyle: "inside", lineHeight: "5px"}}>
                        {OncoData.map(data => {
                            return <ListItem className={classes.descriptionItem}>
                                {data}
                            </ListItem>
                        })}
                    </List>
                </DialogContent>
            </Dialog>
            <Dialog onClose={handleClose} open={wgsDialog}>
                <DialogTitle className={classes.dialog_title}>Подсистема проводит расчет по следующим
                    заболеваниям:</DialogTitle>
                <DialogContent>
                    <List style={{listStyle: "inside", lineHeight: "5px"}}>
                        <ListItem className={classes.descriptionItem}>
                            муковисцидоз;
                        </ListItem>
                        <ListItem className={classes.descriptionItem}>
                            фенилкетонурия.
                        </ListItem>
                    </List>
                    <Typography className={classes.dialog_title}>Также вычисления рекомендаций по использованию и дозированию лекарственных веществ
                        реализованы расчеты по следующим лекарственным веществам:</Typography>
                    <List style={{listStyle: "inside", lineHeight: "5px"}}>
                        {WgsData.map(data => {
                            return <ListItem className={classes.descriptionItem}>
                                {data}
                            </ListItem>
                        })}
                    </List>
                </DialogContent>
            </Dialog>
            <Dialog onClose={handleClose} open={biotaDialog}>
                <DialogTitle className={classes.dialog_title}>Подсистема проводит анализ защищенности микробиотой пациента от следующих заболеваний:</DialogTitle>
                <DialogContent>
                    <List style={{listStyle: "inside", lineHeight: "5px"}}>
                        {BiotaData.map(data => {
                            return <ListItem className={classes.descriptionItem}>
                                {data}
                            </ListItem>
                        })}
                    </List>
                </DialogContent>
            </Dialog>
        </Grid>
    );
}
