import React, {useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {getModulIdByName} from "../../../App/helpers/getModulIdByName";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {DatabaseActions} from "../../actions/database";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import LensIcon from '@mui/icons-material/Lens';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import format from "date-fns/format"
import Box from '@mui/material/Box';
import {ru} from "date-fns/locale"
import clsx from "clsx";
import {Link} from "@mui/material";

const useStyles = makeStyles(theme => ({
    download_report_link: {
        color: "#2595FF",
        transition:"0.3s",
        textDecoration: 'none',
    },
    download_report_link_diabled:{
        color: "gray",
        transition:"0.3s",
        textDecoration: 'none',
        fontSize:"14px"
    },
    download_report_icon: {
        height: "16px",
        width: "16px"
    },
    status_indicator: {
        padding: "5px",
        fontFamily: 'Open Sans',
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "12px",
        lineHeight: "16px",
        borderRadius: "2px",
        width: "max-content"
    },
    status_indicator_gainsboro: {
        color: "gray",
        background: "gainsboro"
    },
    status_indicator_light_blue: {
        color: "#2f7fff",
        background: "#caddff"
    },
    status_indicator_green: {
        color: "#009132",
        background: "#E4F6E9"
    },
    status_indicator_orange: {
        color: "#ff4600",
        background: "#FEECB3"
    },
    status_indicator_red: {
        color: "red",
        background: "#ffd2d2"
    },
    status_indicator_blue: {
        color: "#0083ff",
        background: "#ccdeff"
    },
    status_indicator_lens: {
        fontSize: "8px",
        marginRight: "2px"
    },
    send_button: {
        fontSize: "13px",
        color: "#2595FF",
        fontWeight: 'normal',
        background: "none",
        fontFamily: 'Open Sans',
        fontStyle: "normal",
        padding: "2px",
        textDecoration: 'underline',
        textTransform: 'none',
        textAlign: 'left',
        lineHeight: '14px',
        "&:hover": {
            background: "none",
            color: "#2595FF",
        }
    },
    edit_button: {
        width: '20px',
        height: '20px',
        color: '#2595FF'
    },
    table_body_cell_open: {
        paddingBottom: 0,
        paddingTop: 0,
    },
    table_body_cell_close: {
        paddingBottom: 0,
        paddingTop: 0,
        border: "none"
    },
    cell: {
        padding: '5px',
        lineHeight: '18px',
        fontSize: '13px'
    }
}))

const accessResourceTypes = ["sample", "positive_control", "negative_control", "forward_sequencing", "reverse_sequencing", "genotype_data"]

const statuses = {
    upload: "Загружается:",
    build: "Сборка:",
    fail: "Ошибка"
}

const mdash = `\u2014`;

export const Row = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {row, popoverClick} = props;
    const {modul_database: modul} = useSelector(state => state.modul);
    const [open, setOpen] = useState(false);

    const onClickOpen = () => {
        setOpen(!open)
    }

    const download = (resource) => {
        dispatch(DatabaseActions.downloadResource(resource.id)).then(() => {
        })
    }

    const getConfiguratorButton = (row, file_name) => {
        return modul.name !== 'Onco' ? <IconButton
            aria-describedby="reports_popover"
            variant="contained"
            component="span"
            onClick={e => popoverClick(e, file_name, row)}
        >
            <KeyboardArrowDownIcon className={classes.download_report_icon}/>
        </IconButton> : null
    }

    const getReportButtons = (row) => {
        return row.reports.map((report, index) => {
            if (report) {
                return <Stack direction="row" alignItems="center" key={report.id}>
                    {report.code === 'custom' && <Button
                        className={classes.send_button}
                        onClick={e => download(report)}
                    >
                        Скачать*
                    </Button>}
                    {report.code === 'default' && <React.Fragment>
                        <Button
                            className={classes.send_button}
                            onClick={e => download(report)}
                        >
                            Скачать
                        </Button>
                        {getConfiguratorButton(row, report.file_name)}
                    </React.Fragment>}
                </Stack>
            }
            return null
        })
    }

    const getStatusIndicator = (id) => {
        let ids = {
            'error': {
                text: "Ошибка",
                style: classes.status_indicator_red
            },
            'created': {
                text: "Создано",
                style: classes.status_indicator_blue
            },
            'in-progress': {
                text: "Обрабатывается",
                style: classes.status_indicator_orange
            },
            'completed': {
                text: "Выполнено",
                style: classes.status_indicator_green
            },
            'in-qc': {
                text: "На рассмотрении",
                style: classes.status_indicator_blue
            },
        }
        return <Typography
            className={clsx(classes.status_indicator, ids[id].style)}
        >
            <LensIcon className={classes.status_indicator_lens}/>
            {ids[id].text}
        </Typography>
    }

    const sendAtlasButton = (interpretation) => {
        return <Button
            onClick={e => sendAtlas(interpretation)}
            className={classes.send_button}
            disabled={Boolean(interpretation.resources.filter(resource => statuses[resource.upload_status]).length)}
        >
            Отправить в обработку
        </Button>
    }

    const sendAtlas = (interpretation) => {
        if (interpretation.resources.length) {
            dispatch(DatabaseActions.sendAtlas(interpretation.id)).then(
                () => {
                    dispatch(DatabaseActions.studies(null, getModulIdByName(modul.name))).then(() => {
                    })
                    dispatch({
                        type: "ALERT_SUCCESS", payload: {
                            message: `Вы успешно отправили исследование на обработку!`,
                            type: 'success'
                        }
                    })
                })
        } else {
            dispatch({
                type: "ALERT_ERROR", payload: {
                    message: `Загрузите файлы!`,
                    type: 'error'
                }
            })
        }
    }

    const editRow = (id) => {
        dispatch({
            type: "STUDY", payload: {
                id: id
            }
        })
    }

    const getArrow = (row) => {
        return <TableCell>
            <Stack direction="row" alignItems="center">
                <IconButton
                    aria-label="Раскрыть"
                    size="small"
                    onClick={onClickOpen}
                >
                    {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </IconButton>
                {row.interpretation.status === undefined &&
                    <IconButton
                        aria-label="edit"
                        component="span"
                        onClick={e => editRow(row.id)}
                    >
                        <ModeEditOutlinedIcon
                            className={classes.edit_button}
                        />
                    </IconButton>
                }
            </Stack>
        </TableCell>
    }

    const getInterpretationDate = (resources) => {
        let date = mdash
        resources.forEach(resource => {
            if (resource.type === "report") {
                date = format(new Date(resource.created_at), 'PPPpp', {locale: ru})
            }
        })
        return date
    }

    return (
        <React.Fragment>
            <TableRow hover>
                {getArrow(row)}
                <TableCell className={classes.cell}>{row.study_instance_uid}</TableCell>
                <TableCell className={classes.cell}>{row.patient_name ? row.patient_name : mdash}</TableCell>
                <TableCell
                    className={classes.cell}>{row.patient_birth_date ? format(new Date(row.patient_birth_date), 'PP', {locale: ru}) : mdash}</TableCell>
                <TableCell
                    className={classes.cell}>{row.medical_institution ? row.medical_institution.name : mdash}</TableCell>
                <TableCell
                    className={classes.cell}>{row.biomaterial_source ? row.biomaterial_source.name : mdash}</TableCell>
                <TableCell
                    className={classes.cell}>{row.interpretation.status ? getStatusIndicator(row.interpretation.status) : sendAtlasButton(row.interpretation)}</TableCell>
                <TableCell
                    className={classes.cell}>{row.sampling_date ? format(new Date(row.sampling_date), 'PPP', {locale: ru}) : mdash}</TableCell>
                <TableCell
                    className={classes.cell}>{row.interpretation.created_at ? format(new Date(row.interpretation.created_at), 'PPPpp', {locale: ru}) : mdash}</TableCell>
                <TableCell
                    className={classes.cell}>{row.created_at ? format(new Date(row.created_at), 'PPPpp', {locale: ru}) : mdash}</TableCell>
                <TableCell className={classes.cell}>{getInterpretationDate(row.interpretation.resources)}</TableCell>
                <TableCell className={classes.cell}>{row.referring_physician_name ? row.referring_physician_name : mdash}</TableCell>
                <TableCell className={classes.cell}>{getReportButtons(row)}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell className={open ? classes.table_body_cell_open : classes.table_body_cell_close}/>
                <TableCell className={open ? classes.table_body_cell_open : classes.table_body_cell_close} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            <Grid container direction="column" justify="center" alignItems="flex-start">
                                {row.interpretation.resources.map(resource => {
                                    if (accessResourceTypes.indexOf(resource.type) !== -1) {
                                        return <Stack direction="row" justifyContent="flex-start" key={resource.id}>
                                            {statuses[resource.upload_status] && <Typography className={classes.download_report_link_diabled}>
                                                {resource.upload_status ? statuses[resource.upload_status] : null} {resource.file_name} ({resource.type})
                                            </Typography>}
                                            {!statuses[resource.upload_status] && <Link
                                                className={classes.download_report_link}
                                                disabled={statuses[resource.upload_status]}
                                                href={`/gen/resources/${resource.id}`}
                                            >
                                                {resource.file_name} ({resource.type})
                                            </Link>}
                                        </Stack>
                                    }
                                    return null
                                })}
                                {row.interpretation.resources.filter(resource => accessResourceTypes.indexOf(resource.type) !== -1).length === 0 &&
                                    <Typography>Нет файлов</Typography>}
                            </Grid>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
