const filter = {
    medicalInstitutionId: null,
    interpretationStatus: null,
    biomaterialSourceId: null,
    studyInstanceUid: null,
    operatorName: null,
    patientName: null,
    startDate: null,
    endDate: null,
    offset: 0,
}
export default function database(state = {
    studies: [],
    study: null,
    total_pages: 0,
    filter: filter
}, action) {
    switch (action.type) {
        case "STUDIES":
            return {
                ...state,
                ...{
                    studies: action.payload.data,
                    total_pages: action.payload.total_pages
                }
            }
        case "FILES_CLEAR":
            return {
                ...state,
                ...{
                    files: {}
                }
            }
        case "STUDY":
            return {
                ...state,
                ...{
                    study: action.payload
                }
            }
        case "FILTER":
            return {
                ...state,
                ...{
                    filter: action.payload
                }
            }
        case "FILTER_CLEAR":
            return {
                ...state,
                ...{
                    filter: filter
                }
            }
        default:
            return state
    }
}
