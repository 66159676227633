import {AuthService} from "../services/authentification";

export const AuthActions = {
    login
}

function login(values, navigate) {
    return dispatch => new Promise((resolve, reject) => {
        AuthService.login(values)
            .then(
                response => {
                    dispatch({type: "AUTH_LOGIN_SUCCESS"})
                    navigate('/');
                    resolve()
                },
                error => {
                    dispatch({
                        type: "ALERT_ERROR", payload: {
                            message: error.message,
                            type: 'error'
                        }
                    })
                    reject(error.message)
                }
            )
    })
}

