export default function modul(state = {
    modul_database: {
        name: "Onco",
        id: 0
    },
    modul_dictionary: {
        name: "medicalInstitutions",
        id: 0
    },
}, action) {
    let name
    switch (action.type) {
        case "MODUL_DATABASE":
            switch (action.payload.id) {
                case 0:
                    name = 'Onco'
                    break;
                case 1:
                    name = 'Wgs'
                    break;
                default:
                    name = 'Microbiome'
            }
            return {
                ...state,
                ...{
                    modul_database: {
                        id: action.payload.id,
                        "name": name
                    },
                }
            }
        case "MODUL_DICTIONARY":
            switch (action.payload.id) {
                case 0:
                    name = 'medicalInstitutions'
                    break;
                default:
                    name = 'biomaterialSource'
            }
            return {
                ...state,
                ...{
                    modul_dictionary: {
                        id: action.payload.id,
                        "name": name
                    },
                }
            }
        default:
            return state
    }
}
