import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {getModulIdByName} from '../../App/helpers/getModulIdByName'
import TableContainer from '@mui/material/TableContainer';
import {DatabaseActions} from '../actions/database'
import Pagination from '@mui/material/Pagination';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from "@mui/material/Skeleton";
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import {Report} from './Data/Report'
import {Row} from './Data/Row';

const useStyles = makeStyles(theme => ({
    footer: {
        paddingTop: "30px",
        paddingBottom: "30px",
        margin: "auto",
        width: "max-content"
    },
    paper: {
        boxShadow: 'none'
    },
    container: {
        height: "calc(100vh - 315px)"
    },
    arrow_cell: {
        width: 20,
    },
    cell: {
        fontWeight: 600,
        padding: '12px 16px 12px 8px',
        fontSize: "13px",
        lineHeight: "14px",
    }
}))


const columns = [
    {field: 'name', name: 'Идентификатор образца', width: 50},
    {field: 'patient_name', name: 'ФИО', width: 100},
    {field: 'date_of_birth', name: 'Дата рождения', width: 100},
    {field: 'lpu', name: 'ЛПУ', width: 100},
    {field: 'biomaterial_source', name: 'Источник биоматериала', width: 100},
    {field: 'status_id', name: 'Статус', width: 150},
    {field: 'date_sample', name: 'Дата забора образца', width: 150},
    {field: 'created_the_laboratory', name: 'Создано в лаборатории', width: 150},
    {field: 'loaded_into_the_system', name: "Загружено\nв систему", width: 150},
    {field: 'interpretation_done', name: 'Выполнена интерпретация', width: 150},
    {field: 'operator', name: 'Оператор', width: 100},
    {field: 'report', name: 'Отчет', width: 100},
];

export const Data = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {loading, setLoading} = props
    const {modul_database: modul} = useSelector(state => state.modul);
    const {studies, total_pages, filter} = useSelector(state => state.database);
    const [resourceName, setResourceName] = useState(null);
    const [useRow, setUseRow] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [page, setPage] = useState(1);

    const popoverClick = (event, resource_name, row) => {
        setUseRow(row)
        setResourceName(resource_name)
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        setPage(1)
        setLoading(false)
    }, [modul.id]);

    useEffect(() => {
        if (!loading) {
            dispatch(DatabaseActions.studies(filter, getModulIdByName(modul.name))).then(r => {
                setLoading(true)
            })
        }
    }, [dispatch, loading]);

    useEffect(() => {
        setLoading(false)
    }, [filter]);

    const toPage = (event, value) => {
        dispatch({ type: "FILTER", payload: {...filter,...{offset: value === 1 ? 0 : (10 * value) - 10}}})
        setPage(value);
        setLoading(false)
    };

    const getSkeleton = () => {
        let i;
        let cells = []
        let rows = []
        for (i = 0; i < 13; i++) {
            cells[i] = <TableCell key={i}><Skeleton variant="text" width="100%" height={40}/></TableCell>
        }
        for (i = 0; i < 9; i++) {
            rows[i] = <TableRow key={i}>{cells}</TableRow>
        }
        return rows
    }

    return (
        <Grid key={modul.id}>
            <Paper className={classes.paper}>
                <Report
                    row={useRow}
                    customReportType={resourceName}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                />
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label='sticky table'>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className={classes.arrow_cell}
                                />
                                {columns.map(column => (
                                    <TableCell
                                        style={column.field === 'patient_name' ? {minWidth: column.width} : null}
                                        className={classes.cell}
                                        key={column.field}
                                    >
                                        {column.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? studies.map((row, index) => (
                                <Row
                                    popoverClick={popoverClick}
                                    key={index}
                                    row={row}
                                />
                            )) : getSkeleton()}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid
                    className={classes.footer}
                >
                    <Pagination
                        onChange={toPage}
                        page={page}
                        count={total_pages}
                        variant="outlined"
                        showFirstButton
                        showLastButton
                    />
                </Grid>
            </Paper>
        </Grid>
    )
}
